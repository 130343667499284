import {
  GET_NOTI,
  GET_NOTI_SUCCESS,
} from "./actionTypes"

export const getNotis = () => ({
  type: GET_NOTI,
})

export const getNotiSuccess = notis => ({
  type: GET_NOTI_SUCCESS,
  payload: notis,
})