import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Utils from '../../pages/Utility'
import { Link } from 'react-router-dom'
export default function Disclaimer() {
  return (
    <>
    <div className="footer-content disclaimer_b padding_60px">
    <h5>Disclaimer</h5>
    <ul>
      <li>At SEO Tag Generator, we strive to provide accurate and up-to-date information and tools to help improve your website's SEO. However, we cannot guarantee the accuracy, completeness, or timeliness of the information or tools provided on our website. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</li>
      <li>We also cannot guarantee the effectiveness of the SEO tools provided on our website, as every website and situation is unique. The tools are provided for informational purposes only and should not be relied upon as a substitute for professional advice.</li>
      <li>By using the SEO Tag Generator website, you agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of the website or reliance on its contents.</li>
      <li>We reserve the right to modify the contents of the website or discontinue any or all of the tools at any time without prior notice.</li>
      <li>If you have any questions or concerns about our website or its contents, please do not hesitate to contact us</li>
    </ul>
  </div>
  <hr className="footer_hr" />

  <Container className="padding_60px" fluid={true}>
    <Row>
      <Col md={6}>{new Date().getFullYear()} © {Utils.projectName}.</Col>
      <Col md={6}>
        <ul class="policy_link">
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          <li>|</li>
          <li><Link to="/cookie-policy">Cookie Policy</Link></li>
        </ul>
      </Col>
    </Row>
  </Container>
    </>
  )
}
