import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Breadcrumb from '../../components/Common/Breadcrumb'
import Utils from '../Utility'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';


const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');
  const [ImageArray, setImageArray] = useState([{ imgurl: '' }]);

  const [data, setData] = useState({
    Title: '',
    name: '',
    Siteurl: '',
    Url: '',
    Description: '',
    Type: '',
    Image: '',
    number: '1'

  });

  // <meta property="og:title" content=This is title>
  // <meta property="og:site_name" content=This is site name>
  // <meta property="og:url" content=https://www.Siteurl.com>
  // <meta property="og:description" content=omg this is description>
  // <meta property="og:type" content=business.business>
  // <meta property="og:image" content="">
  // <meta property="og:image" content="">




  const title = data.Title
  const name = data.name
  const url = data.Siteurl
  const description = data.Description
  const type = data.Type



  const [predata, setPreData] = useState({})

  useEffect(() => {
    setPreData({

    })
  }, [data])

  const handleChange = (e, index) => {
    // console.log('==>>',e.target.name, "  ",e.target.value);
    if (e.target.name == "imageurl") {
      let temp = Object.assign([], ImageArray)
      temp[index].imgurl = e.target.value
      setImageArray(temp)
      // console.log('=-=>',JSON.stringify(ImageArray))
    }
    if (e.target.name == "Type") {
      var value = e.target.name.split().toLowerCase()
      setData({ ...data, [e.target.name]: e.target.value ? value : "" })
    } else
      setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }

  const CopyURL = () => {

    let nameCont = `<meta property="og:site_name" content="${name}" > \n`
    let titleCont = ` <meta property="og:title" content="${title}" >\n`
    let urlCont = `<meta property="og:url" content="${url}" >\n`
    let descriptionCont = `<meta property="og:description" content="${description}" >\n`
    let typeCont = `<meta property="og:type" content="${type}" >\n`

    var ImageURLS = ''
    ImageArray?.map((item, index) => {
      ImageURLS = ImageURLS + `<meta property="og:image" content=${item?.imgurl}>\n`
    })
    console.log("====urls =>", ImageURLS)

    var copyData = titleCont + nameCont + urlCont + descriptionCont + typeCont + ImageURLS
    navigator.clipboard.writeText(copyData);

  }

  const handleNumChange = (e) => {

    let length = ImageArray.length
    var num = Number(e.target.value)
    var arr = []
    if (e.target.value < length) {
      arr = ImageArray.slice(0, e.target.value)
      setImageArray(arr)
    } else {
      for (let i = Number(length); i < num; i++) {
        arr.push({ imgurl: '' });
      }
      let newvar = ImageArray.concat(arr);
      // console.log(JSON.stringify(ImageArray)," =====> ",JSON.stringify(arr))
      setImageArray(newvar)
    }
  }

  const reset = () => {
    setData({
      Title: '',
      Siteurl: '',
      Url: '',
      Description: '',
      Type: '',
      Image: '',
      number: '1'
    })
    setImageArray([{ imgurl: '' }])
  }

  const handleValidSubmit = () => {
    CopyURL()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Open Graph Tag Generator - SeoTagGenerator</title>
        <meta name="description" content="Free online Open Graph Generator Tool. You can generate open graph meta tags for website or blogs with just the URLs." />
        <meta name="keywords" content="Open Graph Tag Generator, Generate open graph tags, OG Tag Generator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/ogtag" />
        <meta name="dc.title" CONTENT="SeoTagGenerator" />
        <meta name="dc.keywords" CONTENT="Open Graph Tag Generator, Generate open graph tags, OG Tag Generator" />
        <meta name="dc.description" CONTENT="Free online Open Graph Generator Tool. You can generate open graph meta tags for website or blogs with just the URLs." />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Open Graph Tag Generator - SeoTagGenerator" />
        <meta property="og:description" content="Free online Open Graph Generator Tool. You can generate open graph meta tags for website or blogs with just the URLs." />
        <meta property="og:url" content="https://www.seotaggenerator.com/ogtag" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content=" Open Graph Tag Generator - SeoTagGenerator " />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Free online Open Graph Generator Tool. You can generate open graph meta tags for website or blogs with just the URLs." />

        <link rel="canonical" href="https://www.seotaggenerator.com/ogtag" />

      </Helmet>
      <div className="page-content pt-5">
        {/* <Loader visible={loading} /> */}
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Breadcrumb title={Utils.projectName} breadcrumbItem="OG Tag Generator" />

          {/* <h1>OG Tag Generator</h1> */}
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}

                    <div className="mb-3">
                      <AvField
                        name="Title"
                        label="Title"
                        type="text"
                        value={data.Title}
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder=""
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="name"
                        label="Site Name"
                        value={data.name}
                        onChange={(e) => handleChange(e)}
                        placeholder=""
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Siteurl"
                        label="Site URL"
                        value={data.Siteurl ? data.Siteurl : ""}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                        placeholder=""
                      />
                    </div>

                    {/* "imagetype": "", */}
                    <div className="mb-3">
                      <AvField
                        name="Description"
                        label="Description"
                        className="form-control"
                        placeholder="Up to 10,000 characters"
                        value={data.Description}
                        multiline={true}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="Type"
                        label="Type"
                        className="form-control"
                        // placeholder="Type"
                        type="select"
                        value={data.Type}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value={"Article"}>Article</option>
                        <option value={"Book"}>Book</option>
                        <option value={"Book Author"}>Book Author</option>
                        <option value={"Book Genre"}>Book Genre</option>
                        <option value={"Business"}>Business</option>

                        <option value={"Article"}>Article</option>
                        <option value={"Book"}>Book</option>
                        <option value={"Fitness Course"}>Fitness Course</option>
                        <option value={"Music Album"}>Music Album</option>
                        <option value={"Music Musician"}>Music Musician</option>
                        <option value={"Music Playlist"}>Music Playlist</option>
                        <option value={"Music Radio Station"}>Music Radio Station</option>
                        <option value={"Music Song"}>Music Song</option>
                        <option value={"Object (Generic Object)"}>Object (Generic Object)</option>
                        <option value={"Place"}>Place</option>
                        <option value={"Product"}>Product</option>
                        <option value={"Product Group"}>Product Group</option>
                        <option value={"Product Item"}>Product Item</option>
                        <option value={"Profile"}>Profile</option>
                        <option value={"Election"}>Election</option>

                        <option value={"Resturant"}>Resturant</option>
                        <option value={"Resturant Menu"}>Resturant Menu</option>
                        <option value={"Resturant Menu Item"}>Resturant Menu Item</option>
                        <option value={"Resturant Menu Section"}>Resturant Menu Section</option>
                        <option value={"Video Episode"}>Video Episode</option>
                        <option value={"Video Movie"}>Video Movie</option>
                        <option value={"Video TV Show"}>Video TV Show</option>
                        <option value={"Video Other"}>Video Other</option>
                        <option value={"Website"}>Website</option>
                      </AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="number"
                        label="Number of Images"
                        className="form-control"
                        placeholder=""
                        type="select"
                        value={data.number ? data.number : '1'}
                        onChange={(e) => handleNumChange(e)}
                        required
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>

                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </AvField>
                    </div>
                    {ImageArray?.map((item, index) => {
                      return (
                        <div className="mb-3">

                          <AvField
                            name="imageurl"
                            label={"Image " + Number(index + 1) + " URL"}
                            className="form-control"
                            placeholder=""
                            value={item.imgurl ? item.imgurl : ""}
                            onChange={(e) => handleChange(e, index)}
                            type="url"
                            required
                          />
                        </div>
                      )
                    })}

                    {/*  */}

                  </div>
                  <Row>

                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <div style={{ backgroundColor: 'black', padding: 10 }}>


                <p style={{ color: '#9CDCFE' }}>{`<meta property=`}<span style={{ color: '#CE8453' }}>"og:site_name"</span> content=<span style={{ color: '#CE8453' }}>"{name}"</span>{`>`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta property=`}<span style={{ color: '#CE8453' }}>"og:title"</span> content=<span style={{ color: '#CE8453' }}>"{title}"</span>{`>`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta property=`}<span style={{ color: '#CE8453' }}>"og:url"</span> content=<span style={{ color: '#CE8453' }}>"{url}"</span>{`>`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta property=`}<span style={{ color: '#CE8453' }}>"og:description"</span> content=<span style={{ color: '#CE8453' }}>"{description}"</span>{`>`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta property=`}<span style={{ color: '#CE8453' }}>"og:type"</span> content=<span style={{ color: '#CE8453' }}>"{type}"</span>{`>`}</p>

                {/* <p style={{color :'#9CDCFE'}}>{`<meta property="og:site_name" content=`}<span style={{color :'#CE8453'}}>"{name}"</span></p>
                <p style={{color :'#9CDCFE'}}>{`<meta property="og:title" content=`}<span style={{color :'#CE8453'}}>"{title}"</span></p>
                <p style={{color :'#9CDCFE'}}>{`<meta property="og:url" content=`}<span style={{color :'#CE8453'}}>"{url}"</span></p>
                <p style={{color :'#9CDCFE'}}>{`<meta property="og:description" content=`}<span style={{color :'#CE8453'}}>"{description}"</span></p>
                <p style={{color :'#9CDCFE'}}>{`<meta property="og:type" content=`}<span style={{color :'#CE8453'}}>"{type}"</span></p> */}


                {ImageArray?.map((item, index) => {
                  return (
                    <p style={{ color: '#9CDCFE' }}>{`<meta property=`}<span style={{ color: '#CE8453' }}>"og:image"</span> content=<span style={{ color: '#CE8453' }}>"{item?.imgurl}"</span>{`>`}</p>
                  )
                })}

              </div>
              <Row>
                <Col>
                  <div className="mt-4 btn_section">
                    <button
                      className="btn left_btn w-50 waves-effect waves-light"
                      type="submit"
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                    <button
                      className="btn right_btn w-50 waves-effect waves-light"
                      type="submit"
                    // onClick={() => CopyURL()}
                    >
                      Copy URL
                    </button>
                  </div>
                </Col>

              </Row>

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>The OG Tag Generator on SEOTagGenerator.com offers several significant benefits to website owners looking to enhance their social media presence. Firstly, it allows you to create custom Open Graph tags for your website's pages, ensuring that they look and perform their best when shared on social media platforms like Facebook and Twitter. This, in turn, can increase your website's visibility, reach, and traffic. Additionally, the OG Tag Generator is a user-friendly and easy-to-use tool, requiring no technical expertise or coding skills. It's also completely free, making it an excellent choice for small businesses and individuals on a tight budget. With its ability to enhance your website's social media performance, the OG Tag Generator is a valuable asset that can help take your online presence to the next level.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>OG tags are crucial for optimizing the appearance of shared content on social media platforms. They help ensure that the right image, title, and description show up when a link is shared, which can significantly improve click-through rates and user engagement. SEOTagGenerator's OG Tag Generator makes it easy to create these tags, even for those without technical expertise. With its intuitive interface, users can quickly generate optimized tags for their web pages and ensure that their content looks its best on social media. This free tool saves time and helps boost website traffic and engagement.
            </p>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What are OG tags, and why are they important for SEO?
            </h6>
            <p>A. OG tags are meta tags used to optimize social media sharing of website content. They improve SEO by providing information such as title, description, and image to social media platforms for better visibility and click-through rates.
            </p>
            <h6>Q. How can I add OG tags to my website?
            </h6>
            <p>A. You can manually add OG tags to your website's HTML code or use an OG tag generator tool like the one on seotaggenerator.com to automatically generate them.
            </p>
            <h6>Q. What social media platforms support OG tags?
            </h6>
            <p>A. Most major social media platforms, including Facebook, Twitter, LinkedIn, and Pinterest, support OG tags.
            </p>
            <h6>Q. Can I customize the OG tags generated by the tool on seotaggenerator.com?</h6>
            <p>A. Yes, you can customize the OG tags by providing your own content or modifying the content generated by the tool.</p>
            <h6>Q. Does the OG tag generator on seotaggenerator.com generate tags for multiple pages of a website?</h6>
            <p>A. Yes, the OG tag generator can generate tags for multiple pages of a website, making it easy to optimize social media sharing for an entire website.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>If you're looking to improve the appearance of your website's links and posts on social media platforms, then the OG Tag Generator on seotaggenerator.com can be a helpful tool. Here is why you should usethis generator:
            </p>
            <ul>
              <li>• Boost social media engagement: The OG tags generated by this tool can enhance the look and feel of your links and posts on social media, making them more visually appealing and likely to attract clicks and shares.
              </li>
              <li>• Improved branding: The OG tags can include your website's logo, colors, and other branding elements, making your links and posts more recognizable and memorable.
              </li>
              <li>• Better search engine visibility: The OG tags can help search engines understand the content of your web pages better, which can improve your website's visibility in search results.
              </li>
              <li>• Save time and effort: The OG Tag Generator on seotaggenerator.com is a quick and easy way to create OG tags for your website, without the need for coding or technical skills.
              </li>
            </ul>
            <p>So, using the OG Tag Generator on seotaggenerator.com can help you enhance your website's social media presence, branding, search engine visibility, and save time and effort in the process.</p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
