import React, { useState } from "react"
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap"
import Utils from "../../pages/Utility";
import CookieConsent, { Cookies } from "react-cookie-consent";

const Footer = () => {
  const [cookies, setCookie] = useState(true);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col_f">
            <div className="footer-card footer_card">
              <h1>Follow us!</h1>
              <ul className="social_media">
                <li><a href="#"><i className="mdi mdi-facebook"></i></a></li>
                <li><a href="#"><i className="mdi mdi-twitter"></i></a></li>
                <li><a href="#"><i className="mdi mdi-youtube"></i></a></li>
                <li><a href="#"><i className="mdi mdi-linkedin"></i></a></li>
                <li><a href="#"><i className="mdi mdi-instagram"></i></a></li>
                <li><a href="#"><i className="mdi mdi-pinterest"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col_f">
            <div className="footer-card">
              <h1>More Tools</h1>
              <ul className="menu">
                <li><Link to="/schema">Generate Schema</Link></li>
                <li><Link to="/twittercard">Twitter Card Generator</Link></li>
                <li><Link to="/robotstxt">Robots txt Generator</Link></li>
                <li><Link to="/hreflang">Hreflang Tag Generator</Link></li>
                <li><Link to="/ogtag">Open Graph Tag Generator</Link></li>
                <li><Link to="/breadcrumbs">Breadcrumbs Generator</Link></li>
                <li><Link to="/metatag">Meta Tags Generator</Link></li>
                <li><Link to="/passgenerator">Password Generator</Link></li>
                <li><Link to="/utmurl">UTM URL Generator</Link></li>
                <li><Link to="/hcard">H Card</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col_f">
            <div className="footer-card">
              <h1>Services</h1>
              <ul className="menu">
                <li><a href="#" target="_blank" rel="noopener">SEO Services</a></li>
                <li><a href="#" target="_blank" rel="noopener">Web Design</a></li>
                <li><a href="#" target="_blank" rel="noopener">Web Development</a></li>
                <li><a href="#" target="_blank" rel="noopener">Video Marketing</a></li>
                <li><a href="#" target="_blank" rel="noopener">Graphic Designing</a></li>
                <li><a href="#" target="_blank" rel="noopener">E-Commerce Marketing</a></li>
                <li><a href="#" target="_blank" rel="noopener">Social Media Marketing</a></li>
                <li><a href="#" target="_blank" rel="noopener">Pay Per Click Management</a></li>
                <li><a href="#" target="_blank" rel="noopener">Content Writing</a></li>
                <li><a href="#" target="_blank" rel="noopener">Lead Generation</a></li>
              </ul>

            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col_f">
            <div className="footer-card">
              <h1>Company</h1>
              <ul className="menu">
                <li><a href="#" target="_blank" rel="noopener">About Us</a></li>
                <li><a href="#" target="_blank" rel="noopener">Our Work</a></li>
                <li><a href="#" rel="noopener">Resources</a></li>
                <li><a href="#" target="_blank" rel="noopener">Careers</a></li>
                <li><a href="#" rel="noopener">Blogs</a></li>
                <li><a href="#" target="_blank" rel="noopener">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card cookie-alert">
        <div className="card-body">
          <h5 className="card-title">&#x1F36A; Do you like cookies?</h5>
          <p className="card-text">We use cookies to ensure you get the best experience on our website.</p>
          <div className="btn-toolbar justify-content-end">

            <a href="#" className="btn left_btn accept-cookies">Accept</a>
          </div>
        </div>
      </div> */}
      {
        cookies && <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"

        cookieName="seotaggenerator"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={1}

      >
       <p> We use cookies on the SEO Tag Generator website to enhance your user experience and improve our services. <br />By continuing to use our website, you consent to our use of cookies in accordance with this Cookies Policy.{" "}</p>
        <button
        className="close_cooki"

          onClick={() => {
            setCookie(false);
          }}
        >
          <i className="mdi mdi-close"></i>
        </button>
      </CookieConsent>
      }


    </React.Fragment>
  )
}

export default Footer
