import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import Footer from '../../components/HorizontalLayout/Footer'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import { Helmet } from 'react-helmet'

const Leads = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [showDays, setShowDays] = useState(true);
  const [showWeek, setShowWeek] = useState(true);
  const [loading, setLoading] = useState(false);
  const [OpenHour, setOpenHour] = useState(false);

  const weekArr = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const [data, setData] = useState({
    "context": "https://schema.org",
    "type": "Article",
    "id": '',
    "headline": "",
    "description": "",
    "imagetype": "ImageObject",
    "imageurl": "",
    "imagewidth": "",
    "imageheight": "",
    "authortype": "Person",
    "authorname": "",
    "publishertype": "Organization",
    "publishername": "",
    "publisherlogotype": "ImageObject",
    "publisherlogourl": "",
    "publisherlogowidth": "",
    "publisherlogoheight": "",
    "datePublished": "",
    "dateModified": "",

    "webname": '',
    "weburl": '',
    "searchurl": '',
    "searchString": '',

    "videoName": "",
    "videoDescription": "",
    "videoThumbnailUrl": "",
    "videoThumbnailUrlArr": [],
    "videoUploadDate": "",

    "VideoDurationMint": '',
    "VideoDurationSec": '',
    "VideoContentUrl": "",
    "VideoEmbedUrl": "",
    "videoSeekAction": '',

    "name": "",
    "image": "",
    "@id": "",
    "url": "",
    "telephone": "",
    "price": "",

    "@type": "",
    "streetAddress": "",
    "addressLocality": "",
    "postalCode": "",
    "addressCountry": "",
    "addressRegion": "",
    "priceRange": "",


  });

  const [preData, setpreData] = useState({})
  // const [locaData, setLocalData] = useState({
  //   "@context": "https://schema.org",
  //   "@type": "LocalBusiness",

  //   "name": data?.name ? data?.name : "",
  //   "image": data?.imageurl ? data?.imageurl : "",
  //   "@id": data?.idurl ? data?.idurl : "",
  //   "url": data?.url ? data?.url : "",
  //   "telephone": data?.phone ? data?.phone : "",
  //   "price": data?.price ? data?.price : "",

  //   "address": {
  //     "@type": "PostalAddress",
  //     "streetAddress": data?.street ? data?.street : "",
  //     "addressLocality": data?.city ? data?.city : "",
  //     "postalCode": data?.zipCode ? data?.zipCode : "",
  //     "addressCountry": data?.country ? data?.country : "",
  //     "addressRegion": data?.state ? data?.state : "",
  //   },
  //   "priceRange": "",
  // })

  useEffect(() => {

    if (data.type == "Article") {
      setpreData({
        "@context": data?.context ? data?.context : "",
        "@type": data?.type ? data?.type : "",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": data.id ? data.id : "",
        },
        "headline": data?.headline ? data?.headline : "",
        "description": data?.description ? data?.description : "",
        "image": {
          "@type": data?.imagetype ? data?.imagetype : "",
          "url": data?.imageurl ? data?.imageurl : "",
          "width": data?.imagewidth ? data?.imagewidth : "",
          "height": data?.imageheight ? data?.imageheight : "",
        },
        "author": {
          "@type": data?.authortype ? data?.authortype : "",
          "name": data?.authorname ? data?.authorname : "",
        },
        "publisher": {
          "@type": "Organization",
          "name": data?.publishername ? data?.publishername : "",
          "logo": {
            "@type": data?.publisherlogotype ? data?.publisherlogotype : "",
            "url": data?.publisherlogourl ? data?.publisherlogourl : "",
            "width": data?.publisherlogowidth ? data?.publisherlogowidth : "",
            "height": data?.publisherlogoheight ? data?.publisherlogoheight : "",
          }
        },
        "datePublished": data.datePublished,
        "dateModified": data.dateModified,
      }
      )
    }
    if (data.type == "Website") {
      setpreData({
        "@context": data?.context ? data?.context : "",
        "@type": data?.type ? data?.type : "",
        "name": data.webname,
        "url": data.weburl,
        "potentialAction": {
          "@type": "SearchAction",
          "target": `${data.searchurl} {search_term_string} ${data.searchString}`,
          "query-input": "required name=search_term_string"
        }
      })
    }
    if (data.type == "Video") {
      setpreData({
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": data.videoName,
        "description": data.videoDescription,
        "thumbnailUrl": data.videoThumbnailUrlArr.length == 1 ? data.videoThumbnailUrlArr[0] : data.videoThumbnailUrlArr,
        "uploadDate": data.videoUploadDate,
        "duration": `PT${data.VideoDurationMint}M${data.VideoDurationSec}S`,
        "contentUrl": data.VideoContentUrl,
        "embedUrl": data.VideoEmbedUrl,
      })
    }
    if (data.type == "Local Busines") {
      let temp = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",

        "name": data?.name ? data?.name : "",
        "image": data?.imageurl ? data?.imageurl : "",
        "@id": data?.idurl ? data?.idurl : "",
        "url": data?.url ? data?.url : "",
        "telephone": data?.phone ? data?.phone : "",
        "price": data?.price ? data?.price : "",

        "address": {
          "@type": "PostalAddress",
          "streetAddress": data?.street ? data?.street : "",
          "addressLocality": data?.city ? data?.city : "",
          "postalCode": data?.zipCode ? data?.zipCode : "",
          "addressCountry": data?.country ? data?.country : "",
          "addressRegion": data?.state ? data?.state : "",
        },
        "priceRange": "",
        "openingHoursSpecification": data?.openingHoursSpecification ? data?.openingHoursSpecification : undefined
      }
      setpreData(temp);
    }
  }, [data])

  // const handleType=(e)=>{
  //   setData({...data, [e.target.name]:e.target.value ? e.target.value :""  })
  // }

  const addOpenHour = () => {

    setOpenHour(true)
    setShowWeek(false)
    var obj = {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      ],
      "opens": "",
      "closes": ""
    }
    let temp = Object.assign({}, data);
    let arr = temp.openingHoursSpecification || [];
    arr.push(obj);
    // console.log(" RR===>>> fill obj", arr)
    // setData({ ...temp, openingHoursSpecification: arr, });
    // setTimeout(() => {
    let preName = temp.name
    temp = { ...temp, name: new Date(), openingHoursSpecification: arr }
    setData(temp);
    setTimeout(() => {
      setData({ ...temp, name: preName });
    }, 200);
    // }, 200);


  }

  const allday = (e) => {
    console.log(e.target.value, typeof (e.target.value), e.target.name)
    var val = e.target.value
    if (e.target.value != "true") {
      console.log('working with check box');
      setShowDays(false)
      var obj = {
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "00:00",
          "closes": "23:59"
        }
      }
      let temp = Object.assign({}, data);
      temp = { ...temp, ...obj };
      setData(temp);
      // console.log(e.target.value, " ===>>> fill obj", temp)
    }
    else {
      let temp = Object.assign({}, data);
      delete temp.openingHoursSpecification;
      temp = { ...temp };
      setData(temp);
      setShowDays(true)
      // console.log(e.target.value, " ===>>> fill obj", temp)
      // console.log(e.target.value, " ===>>> empty obj")
    }

  }



  const handleChange = (e) => {
    console.log('==>>', e.target.name, "  ", e.target.value);
    if (e.target.name == "videoSeekAction") {
      if (e.target.value) {
        var obj = {
          "potentialAction": {
            "@type": "SeekToAction",
            "target": `${e.target.value}={seek_to_second_number}`,
            "startOffset-input": "required name=seek_to_second_number"
          }
        }
        let temp = Object.assign({}, preData);
        temp = { ...temp, ...obj };
        setpreData(temp);
        console.log(e.target.value, " ===>>> fill obj", temp)
      }
      else {
        let temp = Object.assign({}, preData);
        var obj = delete temp.potentialAction;
        temp = { ...temp, ...obj };
        setpreData(temp);
        console.log(e.target.value, " ===>>> fill obj", temp)
        console.log(e.target.value, " ===>>> empty obj")
      }
    }
    if (e.target.name == "phone") {
      var value = e.target.value.split(" ").join("")
      setData({ ...data, [e.target.name]: value ? value.length < 2 ? "0" + value : value : "" })
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
    }


  }
  const addThumb = () => {
    var arr = data.videoThumbnailUrlArr
    let temp = {}
    temp.URL = data.videoThumbnailUrl
    // temp[index].action = data.allowRobo
    data.videoThumbnailUrlArr.push(temp)
    setData({ ...data, videoThumbnailUrl: '', })

    // data.videoThumbnailUrlArr.push({ "URL" : e.target.value })
    setData({ ...data, videoThumbnailUrlArr: arr, videoThumbnailUrl: '' })
  }
  const handleValidSubmit = () => {
    copy()
  }

  const deleteThumb = (e, index) => {
    let temp = Object.assign({}, data);
    temp.videoThumbnailUrlArr.splice(index, 1)
    console.log("data =>", temp.domainArr)
    setData(temp);

  }

  const copy = () => {
    var copyData = "<script  type=" + "application/ld+json >" + `\n` + JSON.stringify(preData) + `\n</script>`
    navigator.clipboard.writeText(copyData);
  }

  const reset = () => {
    setData({
      ...data,
      "context": "https://schema.org/",   //url

      "id": '',
      "headline": "",
      "description": "",
      "imagetype": "ImageObject",
      "imageurl": "",
      "imagewidth": "",
      "imageheight": "",
      "authortype": "Person",
      "authorname": "",
      "publishertype": "Organization",
      "publishername": "",
      "publisherlogotype": "ImageObject",
      "publisherlogourl": "",
      "publisherlogowidth": "",
      "publisherlogoheight": "",
      "datePublished": "",
      "dateModified": "",



      "webname": '',
      "weburl": '',
      "searchurl": '',
      "searchString": '',

      "videoName": "",
      "videoDescription": "",
      "videoThumbnailUrl": "",
      "videoThumbnailUrlArr": [],
      "videoUploadDate": "",

      "VideoDurationMint": '',
      "VideoDurationSec": '',
      "VideoContentUrl": "",
      "VideoEmbedUrl": "",
      "videoSeekAction": '',
    })
  }

  const setDays = (e, index, index2, item) => {
    let name = e.target.name
    let value = e.target.value
    if (value == "false") {
      let temp = Object.assign({}, preData);
      let arr = temp?.openingHoursSpecification
      // let temp = Object.assign([], ImageArray)
      arr[index].dayOfWeek.push(name)
      let preName = temp.name
      temp = { ...temp, name: new Date(), }
      setpreData(temp);
      setTimeout(() => {
        setpreData({ ...temp, name: preName });
      }, 200);
    } else {
      let temp = Object.assign({}, preData);

      temp.openingHoursSpecification[index].dayOfWeek?.map((days, indexx) => {
        if (days == item) {
          temp.openingHoursSpecification[index].dayOfWeek.splice(indexx, 1)

          let preName = temp.name
          temp = { ...temp, name: new Date(), }
          setpreData(temp);
          setTimeout(() => {
            setpreData({ ...temp, name: preName });
          }, 200);
        }
      })
    }
    console.log("===console while seting days=> ", index, index2, item, name, value, preData)


  }
  const deleteWeek = (item, index) => {
    let temp = Object.assign({}, preData);
    temp.openingHoursSpecification.splice(index, 1)

    let preName = temp.name
    temp = { ...temp, name: new Date(), }
    setData(temp);
    setTimeout(() => {
      setData({ ...temp, name: preName });
    }, 200);

    if (temp?.openingHoursSpecification?.length < 1) {
      setShowWeek(true)
      setOpenHour(false)
      setTimeout(() => {
        let temp = Object.assign({}, data);
        temp.openingHoursSpecification = undefined;
        temp = { ...temp };
        setData(temp);

      }, 200);

    }
  }
  const updateTime = (e, index, item) => {
    let name = e.target.name
    let value = e.target.value

    let temp = Object.assign({}, preData);
    let arr = temp?.openingHoursSpecification
    // let temp = Object.assign([], ImageArray)
    // arr[index].name = value
    if (name == "opens") {
      arr[index].opens = value
    } else {
      arr[index].closes = value
    }
    // closes: ''
    let preName = temp.name
    temp = { ...temp, name: '- ', }
    setData(temp);
    setTimeout(() => {
      setData({ ...temp, name: preName });
    }, 200);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Schema Markup Generator - SeoTagGenerator</title>
        <meta name="description" content="Optimize your website's SEO with the Schema.org Markup Generator. Create structured data using JSON-LD and microdata to boost your visibility in search results, increase organic traffic, and enhance user experience. Start now!" />
        <meta name="keywords" content="Schema Markup Generator (JSON-LD), free schema generator, Schema Markup Generator, Schema Markup Generator Tool, Local Business Schema Markup Generator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/schema" />
        <meta name="dc.title" CONTENT=" SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT="Schema Markup Generator (JSON-LD), free schema generator, Schema Markup Generator, Schema Markup Generator Tool, Local Business Schema Markup Generator " />
        <meta name="dc.description" CONTENT="Optimize your website's SEO with the Schema.org Markup Generator. Create structured data using JSON-LD and microdata to boost your visibility in search results, increase organic traffic, and enhance user experience. Start now!" />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Schema Markup Generator - SeoTagGenerator" />
        <meta property="og:description" content="Optimize your website's SEO with the Schema.org Markup Generator. Create structured data using JSON-LD and microdata to boost your visibility in search results, increase organic traffic, and enhance user experience. Start now!" />
        <meta property="og:url" content="https://www.seotaggenerator.com/schema/" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="Schema Markup Generator - SeoTagGenerator" />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Optimize your website's SEO with the Schema.org Markup Generator. Create structured data using JSON-LD and micro data to boost your visibility in search results, increase organic traffic, and enhance user experience. Start now!" />
        <link rel="canonical" href="https://www.seotaggenerator.com/schema" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          {/* <h1>Schema Markup Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="Schema Markup Generator" />
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}

                    <div className="mb-3">
                      <AvField
                        name="type"
                        label="@Artical Type"
                        className="form-control"
                        placeholder="Artical"
                        type="select"
                        value={data.type ? data.type : "Artical"}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value={"Article"}>Article</option>
                        <option value={"Local Busines"}>Local Business</option>
                        {/* <option value={"Blogposting"}>Blog Posting</option>  */}
                        <option value={"Website"}>Website</option>
                        <option value={"Video"}>Video</option>
                      </AvField>
                    </div>
                    {data.type == "Article" &&
                      <div>
                        <div className="mb-3">
                          <AvField
                            name="id"
                            label="URL"
                            className="form-control"
                            placeholder="Enter URL"
                            value={data.id}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="headline"
                            label="Artical Headline"
                            type="text"
                            value={data.headline}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Max of 110 Characters"
                            validate={{ maxLength: { value: 110 } }}
                          />
                          <span>{data.headline.length}/110</span>
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="description"
                            label="Description"
                            value={data.description}
                            onChange={(e) => handleChange(e)}
                            placeholder="Short description of the artical"
                            type="text"

                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="imageurl"
                            label="Image URL"
                            value={data.imageurl}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            required
                            placeholder="Image URL"
                          />
                        </div>

                        {/* "imagetype": "", */}
                        <div className="mb-3">
                          <AvField
                            name="imagewidth"
                            label="Image Width"
                            className="form-control"
                            placeholder="Image Width"
                            value={data.imagewidth}
                            onChange={(e) => handleChange(e)}
                            type="number"

                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="imageheight"
                            label="Artical Height"
                            type="number"
                            value={data.imageheight}
                            onChange={(e) => handleChange(e)}

                            placeholder="Image Height"
                          />
                        </div>

                        {/* Author Type */}
                        <div className="mb-3">
                          <AvField
                            name="authortype"
                            label="@Author Type"
                            className="form-control"
                            value={data.authortype}
                            onChange={(e) => handleChange(e)}
                            placeholder="Artical"
                            type="select"

                          >
                            <option value={"Person"}>Person</option>
                            <option value={"Organization"}>Organization</option>
                          </AvField>
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="authorname"
                            label="Author Name"
                            type="text"
                            value={data.authorname}
                            onChange={(e) => handleChange(e)}

                            placeholder="Author Name"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="publishername"
                            label="Publisher Name"
                            type="text"
                            value={data.publishername}
                            onChange={(e) => handleChange(e)}

                            placeholder="Publisher Name"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="publisherlogourl"
                            label="Publisher Logo URL"
                            type="url"
                            value={data.publisherlogourl}
                            onChange={(e) => handleChange(e)}

                            placeholder="Publisher Logo URL"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="publisherlogowidth"
                            label="Publisher Logo Width"
                            type="number"
                            value={data.publisherlogowidth}
                            onChange={(e) => handleChange(e)}

                            placeholder="Publisher Logo Width"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="publisherlogoheight"
                            label="Publisher Logo Height"
                            value={data.publisherlogoheight}
                            onChange={(e) => handleChange(e)}
                            type="number"

                            placeholder="Publisher Logo Height"
                          />
                        </div>

                        {/* Date Published */}
                        <div className="mb-3">
                          <AvField
                            name="datePublished"
                            label="Date Published"
                            value={data.datePublished}
                            onChange={(e) => handleChange(e)}
                            type=""

                            placeholder="Date Published"
                          />
                        </div>
                        {/* Date Modified */}
                        <div className="mb-3">
                          <AvField
                            name="dateModified"
                            label="Date Modified"
                            value={data.dateModified}
                            onChange={(e) => handleChange(e)}
                            type="date"

                            placeholder="Date Modified"
                          />
                        </div>
                      </div>
                    }
                    {data.type == "Website" &&
                      <div>
                        <div className="mb-3">
                          <AvField
                            name="webname"
                            label="Name"
                            className="form-control"
                            placeholder="Website's name"
                            value={data.webname}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="weburl"
                            label="Website's URL"
                            type="url"
                            value={data.weburl}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="URL"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="searchurl"
                            label="Internal site search URL"
                            value={data.searchurl}
                            onChange={(e) => handleChange(e)}
                            placeholder="(e.g. https://example.com/search?q=)"
                            type="url"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="searchString"
                            label="string in the search URL after the query"
                            value={data.searchString}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    }
                    {data.type == "Video" &&
                      <div>
                        <div className="mb-3">
                          <AvField
                            name="videoName"
                            label="Name"
                            className="form-control"
                            placeholder="Website's name"
                            value={data.videoName}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="videoDescription"
                            label="Video's description"
                            type="text"
                            value={data.videoDescription}
                            onChange={(e) => handleChange(e)}

                            placeholder="Video's Description"
                          />
                        </div>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <AvField
                                name="videoUploadDate"
                                label="Upload Date"
                                value={data.videoUploadDate}
                                onChange={(e) => handleChange(e)}
                                placeholder="Upload Date"
                                type="date"

                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <AvField
                                name="VideoDurationMint"
                                label="Minutes"
                                value={data.VideoDurationMint}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                placeholder="Minutes"

                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <AvField
                                name="VideoDurationSec"
                                label="Second"
                                value={data.VideoDurationSec}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                placeholder="Second"

                              />
                            </div>
                          </Col>
                        </Row>
                        <div style={{ 'alignItems': 'center' }}>
                          <Row>
                            <Col md={8}>
                              <div className="mb-3">
                                <AvField
                                  name="videoThumbnailUrl"
                                  label="Thumbnail URL"
                                  value={data.videoThumbnailUrl}
                                  onChange={(e) => handleChange(e)}
                                  type="url"
                                  placeholder="Thumbnail URL"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              {data.videoThumbnailUrlArr.length < 4 &&
                                <button type='button' className="btn btn-primary mt-20" onClick={() => addThumb()}>
                                  <div>
                                    <i className='mdi mdi-check' />
                                  </div>
                                </button>
                              }
                            </Col>

                          </Row>
                        </div>
                        {data.videoThumbnailUrlArr?.map((item, index) => {
                          return (
                            <div style={{ 'alignItems': 'center' }}>
                              <Row>
                                <Col md={8}>
                                  <div className="mb-3">
                                    <AvField
                                      name="videoThumbnailUrl"
                                      label={"Thumbnail URL #" + index + 1}
                                      value={data.videoThumbnailUrl}
                                      // onChange={(e) => handleChange(e)}
                                      type="url"
                                      placeholder="Thumbnail URL"
                                    />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <button type='button' className="btn btn-primary mt-20" onClick={() => deleteThumb()}>
                                    <div>
                                      <i className='mdi mdi-close' />
                                    </div>
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          )
                        })}
                        <div className="mb-3">
                          <AvField
                            name="VideoContentUrl"
                            label="Content URL"
                            value={data.VideoContentUrl}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            placeholder="Video Content Url"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="VideoEmbedUrl"
                            label="Embed Url"
                            value={data.VideoEmbedUrl}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            placeholder="Video Embed Url"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="videoSeekAction"
                            label="Seek to action Target URL"
                            value={data.videoSeekAction}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            placeholder=""
                          />
                        </div>
                      </div>
                    }
                    {data.type == "Local Busines" &&
                      <div className="p-2">

                        <div className="mb-3">
                          <AvField
                            name="name"
                            label="Name"
                            value={data.name}
                            onChange={(e) => handleChange(e)}
                            placeholder="Name"
                            type="text"
                          // required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="imageurl"
                            label="Image URL"
                            className="form-control"
                            placeholder="Image URL"
                            value={data.imageurl}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="idurl"
                            label="ID URL"
                            className="form-control"
                            placeholder="@ID URL"
                            value={data.idurl}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="url"
                            label="URL"
                            className="form-control"
                            placeholder="URL"
                            value={data.url}
                            onChange={(e) => handleChange(e)}
                            type="url"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="phone"
                            label="Phone"
                            value={data.phone}
                            onChange={(e) => handleChange(e)}
                            placeholder="Phone Number"
                            // type="tel"
                            type="text" validate={{ number: true }}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="price"
                            label="Price Range"
                            value={data.price}
                            onChange={(e) => handleChange(e)}
                            type="number"
                            // required
                            placeholder="Price Range"
                          />
                        </div>

                        {/* "imagetype": "", */}
                        <div className="mb-3">
                          <AvField
                            name="street"
                            label="Street"
                            className="form-control"
                            placeholder="Street"
                            value={data.street}
                            onChange={(e) => handleChange(e)}
                            type="text"
                          // required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="city"
                            label="City"
                            className="form-control"
                            placeholder="City"
                            value={data.city}
                            onChange={(e) => handleChange(e)}
                            type="text"
                          // required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="zipCode"
                            label="Zip Code"
                            className="form-control"
                            placeholder="Zip Code"
                            value={data.zipCode}
                            onChange={(e) => handleChange(e)}
                            type="number"
                          // required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="state"
                            label="State"
                            className="form-control"
                            placeholder="State"
                            value={data.state}
                            onChange={(e) => handleChange(e)}
                            type="text"
                          // required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="country"
                            label="Country"
                            className="form-control"
                            placeholder="Country"
                            value={data.country}
                            onChange={(e) => handleChange(e)}
                            type="text"
                          // required
                          />
                        </div>
                        {/* Week here */}
                        {OpenHour &&
                          <div>
                            {preData?.openingHoursSpecification?.map((item, index) => {
                              return (
                                <div className="mt-5">
                                  <Row>
                                    <div className='d-flex flex-row-reverse'>
                                      <button
                                        className="btn btn-primary mt-10 mb-5"
                                        onClick={(e) => deleteWeek(item, index)}
                                        style={{ backgroundColor: 'red', borderWidth: 0 }}
                                      >
                                        <i className='mdi mdi-close' />
                                      </button>
                                    </div>
                                    {weekArr.map((item, index2) => {
                                      return (
                                        <Col md={4}>
                                          <div className="mb-3">
                                            <AvGroup check>
                                              <AvInput type="checkbox" name={item} onChange={(e) => setDays(e, index, index2, item)} value={"Monday"} /> {item ? item : undefined}
                                            </AvGroup>
                                          </div>
                                        </Col>
                                      )
                                    })}

                                  </Row>
                                  <Row>
                                    <Col md={6}>
                                      <AvField
                                        name="opens"
                                        label="Opens At"
                                        className="form-control"
                                        placeholder=""
                                        value={item.opens}
                                        onChange={(e) => updateTime(e, index)}
                                        type="time"
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <AvField
                                        name="closes"
                                        label="Close At"
                                        className="form-control"
                                        placeholder=""
                                        value={item.closes}
                                        onChange={(e) => updateTime(e, index)}
                                        type="time"
                                      />
                                    </Col>

                                  </Row>
                                </div>
                              )
                            })}
                          </div>
                        }
                        <div className='mt-2'>
                          <Row>
                            {showWeek ?
                              <Col md={5}>
                                <div className="mb-3">
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="open" onChange={(e) => allday(e)} value={data.open} /> Open 24/7
                                  </AvGroup>
                                </div>
                              </Col>
                              : null}
                            {showDays ?
                              <Col md={7}>
                                <div className="btn btn-primary mb-3 mt-20" onClick={(e) => addOpenHour(e)} style={{ backgroundColor: "#3b5de" }} >
                                  <span>+ Add opening Hours</span>
                                </div>
                              </Col>
                              : null}
                          </Row>

                        </div>

                      </div>

                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <JSONInput
                id='a_unique_id'
                placeholder={preData}
                // colors      = { darktheme }
                locale={locale}
                height='550px'
                width='100%'
                viewOnly={true}
              />

              <Row>
                <Col>
                  <div className="mt-4 btn_section">
                    <button
                      className="btn  w-50 waves-effect waves-light left_btn"
                      type="button"
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                    <button
                      className="btn  w-50 waves-effect waves-light right_btn"
                      type="button"
                      onClick={() => copy()}
                    >
                      Copy
                    </button>

                  </div>
                </Col>

              </Row>
            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>The Schema Markup Generator tool on SEOTagGenerator.com is highly customizable, allowing users to create Schema.org based schema markup code tailored to their specific needs. Users can choose from a variety of schema types, including article, book, event, product, and more. They can also enter details such as the name, description, price, and image of their products or services to ensure that search engines understand their website's content better.</p>
            <p>With the Schema Markup Generator tool, users can input information about their content, products, or services, and the tool will generate the necessary code to add to their website's HTML. This makes it easy for even those with little coding experience to add schema markup to their website, improving its search engine rankings and visibility.
            </p>
            <p>In addition to being easy to use and highly customizable, the Schema Markup Generator tool on SEOTagGenerator.com is also free, making it accessible to businesses and website owners of all sizes. With this powerful tool at their disposal, users can improve their website's search engine rankings, increase their online visibility, and attract more traffic to their website.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>SEOTagGenerator.com's  Schema.org structured data generator tool provides numerous benefits to businesses and website owners looking to improve their online visibility and search engine rankings. Here are some of the key benefits of using this tool:</p>
            <ul>
              <li>• Improves search engine visibility: By adding schema markup code to your website, you can provide search engines with more information about your content, products, or services. This, in turn, can lead to better search engine rankings and increased visibility.
              </li>
              <li>• Enhances user experience: Schema markup code can help search engines display your website's content in a more organized and visually appealing way, making it easier for users to find what they're looking for.
              </li>
              <li>• Increases click-through rates: When search engines display rich snippets of your content, products, or services in search results, users are more likely to click through to your website, resulting in increased traffic.
              </li>
              <li>• Easy to use: The Schema Markup Generator tool on SEOTagGenerator.com is easy to use, even for those with little coding experience. Users simply input information about their content, products, or services, and the tool generates the necessary code for them.
              </li>
              <li>• Highly customizable: The tool allows users to choose from a variety of schema types and customize the code to their specific needs. This means that businesses can create schema markup code that accurately reflects their products or services and ensures that search engines understand their content better.
              </li>
              <p className='mt-3'>Overall, SEOTagGenerator.com's Schema Markup Generator tool is a powerful tool for businesses and website owners looking to improve their online presence. By utilizing this tool, users can enhance their website's search engine visibility, improve user experience, and increase click-through rates, all while being easy to use and highly customizable.
              </p>
            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What is a schema markup generator?</h6>
            <p>A. A schema markup generator is an online tool that allows users to create schema markup code for their website easily and quickly. Schema markup is a type of code that provides search engines with more information about a website's content, products, or services, improving its search engine visibility and rankings.</p>
            <h6>Q. How does SEOTagGenerator.com's Schema Markup Generator tool work?</h6>

            <p>A. SEOTagGenerator.com's Schema Markup Generator tool allows users to input information about their content, products, or services, and the tool generates the necessary schema markup code for them.</p>
            <h6>Q. Do I need coding experience to use the Schema Markup Generator tool?</h6>
            <p>A. No, you don't need coding experience to use SEOTagGenerator.com's Schema Markup Generator tool. The tool is designed to be easy to use, even for those with little coding experience.</p>
            <h6>Q. What are the benefits of using a schema markup generator?</h6>
            <p>A. Using a schema markup generator can improve a website's search engine visibility, enhance user experience, increase click-through rates, and save time and effort in creating schema markup code.</p>
            <h6>Q. Is the Schema Markup Generator tool on SEOTagGenerator.com free?</h6>
            <p>A. Yes, the Schema Markup Generator tool on SEOTagGenerator.com is free to use. There are no hidden fees or charges, making it accessible to businesses and website owners of all sizes.</p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>Using the Schema.org structured data generator Generator tool on SEOTagGenerator.com can greatly benefit your website's search engine visibility, user experience, and click-through rates. By creating schema markup code that accurately reflects your content, products, or services, search engines can better understand and display your website's information to users. This can lead to higher search engine rankings, increased traffic, and ultimately, more conversions. Additionally, the tool is easy to use and highly customizable, making it accessible to businesses and website owners of all sizes, regardless of coding experience. Overall, using the Schema Markup Generator tool on SEOTagGenerator.com is a simple yet effective way to improve your website's online presence and achieve your business goals.</p>

          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
