import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'

export default function index() {
  return (
    <div className='home-page-section'>
    {/* <img src={require('../../assets/images/bg-img.jpg')} className="banner-img-home"  alt="bg-img" /> */}
    <Container fluid>
      <header>
        <div className='logo-h'>
          <Link to="/"><img src={require('../../assets/images/seotaggenerator.png')} alt="seotaggenerator" /></Link>
        </div>
      </header>
    </Container>
    <Container>
    <div className='policy_page'>
        <h3>COOKIES POLICY</h3>
        <p>We use cookies on the SEO Tag Generator website to enhance your user experience and improve our services. By continuing to use our website, you consent to our use of cookies in accordance with this Cookies Policy.</p>
        <p>Cookies are small data files that are placed on your device when you visit our website. We use both session cookies and persistent cookies to help us improve the functionality of our website and track website analytics.</p>
        <p>We may also use third-party cookies for advertising or other purposes. You can control and manage cookies in your browser settings, but disabling cookies may limit your ability to use certain features of our website.</p>
        <p>Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.</p>
        <p>We reserve the right to modify this Cookies Policy at any time without prior notice. If you have any questions or concerns about our use of cookies, please contact us</p>
    </div>
    </Container>

  </div>
  )
}
