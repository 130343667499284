import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"

import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Breadcrumb from '../../components/Common/Breadcrumb'
import Utils from '../Utility'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'

const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');
  const [ImageArray, setImageArray] = useState([{ imgurl: '' }]);
  const [data, setData] = useState({
    sitetitle: '',
    description: '',
    sitekeyword: '',
    // : '',
    Type: '',
    Image: '',
    number: '1'
  });

  const [sitetitle, setSitetitle] = useState('');
  const [description, setDescription] = useState('');
  const [sitekeyword, setSitekeyword] = useState('');
  const [days, setDays] = useState('');
  const [authorname, setAuthorname] = useState('');
  const [applicationname, setApplicationname] = useState('');

  const [allowRobo, setAllowRobo] = useState(true);
  const [allowRobotolink, setAllowRobotolink] = useState(true);
  const [revisitePage, setRevisitePage] = useState(true);
  const [author, setAuthor] = useState(true);
  const [application, setApplication] = useState(true);



  // <meta property="og:title" content=This is title>
  // <meta property="og:site_name" content=This is site name>
  // <meta property="og:url" content=https://www.Siteurl.com>
  // <meta property="og:description" content=omg this is description>
  // <meta property="og:type" content=business.business>
  // <meta property="og:image" content="">
  // <meta property="og:image" content="">



  const [predata, setPreData] = useState({})

  useEffect(() => {
    setPreData({

    })
  }, [data])

  const handleChange = (e, index) => {
    // console.log('==>>',e.target.name, "  ",e.target.value);
    if (e.target.name == "imageurl") {
      let temp = Object.assign([], ImageArray)
      temp[index].imgurl = e.target.value
      setImageArray(temp)
      // console.log('=-=>',JSON.stringify(ImageArray))
    }
    if (e.target.name == "Type") {
      var value = e.target.name.split().toLowerCase()
      setData({ ...data, [e.target.name]: e.target.value ? value : "" })
    } else
      setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }
  const handleValidSubmit = () => {
    CopyURL()
  }

  const CopyURL = () => {
    let titl = `<meta name="title" content="${sitetitle} > \n`
    let des = `<meta name="description" content="${description}" >\n`
    let key = `<meta name="keywords" content="${sitekeyword}" >\n`
    let robo = allowRobotolink ? `<meta name="robots" content="${allowRobo ? "index" : "noindex"}, nofollow" >\n` : ""
    let revi = revisitePage ? `<meta name="revisit-after" content="${days}" >\n` : ''
    let auth = author ? `<meta name="author" content="${authorname}" >\n` : ''
    let appli = application ? `<meta name="application-name" content="${applicationname}" >\n` : ''


    var copyData = titl + des + key + robo + revi + auth + appli

    navigator.clipboard.writeText(copyData);

  }

  const handleNumChange = (e) => {

    let length = ImageArray.length
    var num = Number(e.target.value)
    var arr = []
    if (e.target.value < length) {
      arr = ImageArray.slice(0, e.target.value)
      setImageArray(arr)
    } else {
      for (let i = Number(length); i < num; i++) {
        arr.push({ imgurl: '' });
      }
      let newvar = ImageArray.concat(arr);
      // console.log(JSON.stringify(ImageArray)," =====> ",JSON.stringify(arr))
      setImageArray(newvar)
    }
  }

  const reset = () => {
    setData({
      Title: '',
      Siteurl: '',
      Url: '',
      Description: '',
      Type: '',
      Image: '',
      number: '1'
    })
    setImageArray([{ imgurl: '' }])
  }

  return (
    <React.Fragment>
      <div className="page-content pt-5">
        {/* <Loader visible={loading} /> */}
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          {/* <h1>Meta Tags Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="Meta Tags Generator" />
          <p className='pb-2'>Meta tags generator is a tool that generates meta tag codes for any website. We make it easy to Generator tags with our tool, all you have to do is, filling out all the sections of this code generator. Then simply “copy & paste” the generated tag in the HTML source of your web page.</p>


          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}

                    <div className="mb-3">
                      <AvField
                        name="sitetitle"
                        label="Site Title"
                        className="form-control"
                        placeholder="Title must be within 70 Characters"
                        value={data.sitetitle}
                        onChange={(e) => setSitetitle(e.target.value)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="description"
                        label="Site Description"
                        className="form-control"
                        placeholder="Description must be within 150 Characters"
                        value={data.description}
                        onChange={(e) => setDescription(e.target.value)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="sitekeyword"
                        label="Site Keywords (Separate with commas)"
                        className="form-control"
                        placeholder="Keyword1, Keyword2, keyword3"
                        value={data.sitekeyword}
                        onChange={(e) => setSitekeyword(e.target.value)}
                        type="text"
                        required
                      />
                    </div>
                    <div style={{}}>
                      <Row>
                        <Col md={6}>
                          <div className="btn left_btn mb-3 mt-20" onClick={() => { setAllowRobo(!allowRobo) }} style={{ backgroundColor: "#3b5de" }} >
                            <span>Allow robots to index you website</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="btn left_btn mb-3 mt-20" onClick={() => setAllowRobotolink(!allowRobotolink)} style={{ backgroundColor: "#3b5de" }} >
                            <span>Allow robots to follow all links</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="btn left_btn mb-3 mt-20" onClick={() => setRevisitePage(!revisitePage)} style={{ backgroundColor: "#3b5de" }} >
                            <span>Search engines should revisite this page after</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <AvField
                            name="days"
                            label="Days"
                            className="form-control"
                            placeholder=""
                            value={data.days}
                            onChange={(e) => setDays(e.target.value)}
                            type="number"
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="btn left_btn mb-3 mt-20" onClick={() => setAuthor(!author)} style={{ backgroundColor: "#3b5de" }} >
                            <span>Author</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="btn left_btn mb-3 mt-20" onClick={() => setApplication(!application)} style={{ backgroundColor: "#3b5de" }} >
                            <span>Application Name</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <AvField
                              name="authorname"
                              label=""
                              className="form-control"
                              placeholder="Please enter Author Name"
                              value={authorname}
                              onChange={(e) => setAuthorname(e.target.value)}
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <AvField
                              name="applicationname"
                              label=""
                              className="form-control"
                              placeholder="Please enter Application Name"
                              value={applicationname}
                              onChange={(e) => setApplicationname(e.target.value)}
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </div>
                  <Row>

                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <div style={{ backgroundColor: 'black', padding: 10, borderRadius: "0px" }}>

                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"title"</span> content=<span style={{ color: '#CE8453' }}>"{sitetitle}"</span>{`>`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"description"</span> content=<span style={{ color: '#CE8453' }}>"{description}"</span>{`>`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"keywords"</span> content=<span style={{ color: '#CE8453' }}>"{sitekeyword}"</span>{`>`}</p>
                {allowRobotolink ? <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"robots"</span> content=<span style={{ color: '#CE8453' }}>"{allowRobo ? "index" : "noindex"}, nofollow"</span>{`>`}</p> : null}
                {revisitePage ? <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"revisit-after"</span> content=<span style={{ color: '#CE8453' }}>"{days}"</span>{`>`}</p> : null}
                {author ? <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"author"</span> content=<span style={{ color: '#CE8453' }}>"{authorname}"</span>{`>`}</p> : null}
                {application ? <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"application-name"</span> content=<span style={{ color: '#CE8453' }}>"{applicationname}"</span>{`>`}</p> : null}

                {/* <p style={{color :'#9CDCFE'}}>{`<meta name="title" content=`}<span style={{color :'#CE8453'}}>"{sitetitle}"</span></p> */}
                {/* // <p style={{color :'#9CDCFE'}}>{`<meta name="description" content=`}<span style={{color :'#CE8453'}}>"{description}"</span></p>
                // <p style={{color :'#9CDCFE'}}>{`<meta name="keywords" content=`}<span style={{color :'#CE8453'}}>"{sitekeyword}"</span></p>

                // {allowRobotolink ? <p style={{color :'#9CDCFE'}}>{`<meta name="robots" content=`}<span style={{color :'#CE8453'}}>"{allowRobo ? "index":"noindex"}, nofollow"</span></p> :null}

                //   <p style={{color :'#9CDCFE'}}>{`<meta name="revisit-after" content=`}<span style={{color :'#CE8453'}}>"{days}"</span></p> : null}
                // <p style={{color :'#9CDCFE'}}>{`<meta name="author" content=`}<span style={{color :'#CE8453'}}>"{authorname}"</span></p>  :null}
                // <p style={{color :'#9CDCFE'}}>{`<meta name="application-name" content=`}<span style={{color :'#CE8453'}}>"{applicationname}"</span></p> :null} */}



              </div>
              <Row>
                <Col>
                  <div className="mt-4 btn_section">
                    <button
                      className="btn left_btn w-50 waves-effect waves-light"
                      type="submit"
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                    <button
                      className="btn right_btn w-50 waves-effect waves-light"
                      type="submit"
                    // onClick={() => CopyURL()}
                    >
                      Copy URL
                    </button>
                  </div>
                </Col>

              </Row>

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>Seotaggenerator.com offers a powerful Meta Tags Generator tool to enhance your website's search engine visibility effortlessly. The tool generates optimized meta tags, including title tags, meta descriptions, and other essential meta tags, with just a few clicks. These meta tags play a crucial role in SEO by providing search engines with valuable information about your website's content. By creating SEO-compliant meta tags, you can drive more organic traffic to your website and improve your online presence. With the easy-to-use Meta Tags Generator on seotaggenerator.com, you can save significant time and effort while boosting your website's search engine rankings.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>Using the Meta Tags Generator on seotaggenerator.com can provide several benefits, including:
            </p>
            <ul>
              <li>• Improved search engine visibility: By creating optimized meta tags that comply with SEO best practices, you can improve your website's visibility in search engine results pages.
              </li>
              <li>• Attract more organic traffic: Optimized meta tags can help attract more organic traffic to your website, which can result in more leads and sales.
              </li>
              <li>• Save time and effort: The Meta Tags Generator is easy to use and can save you a lot of time and effort. With just a few clicks, you can create optimized meta tags for your web pages.</li>
              <li>• Comply with SEO best practices: The Meta Tags Generator on seotaggenerator.com helps you generate meta tags that comply with SEO best practices, ensuring that your website is optimized for search engines.</li>
              <li>• Improve online presence: By improving your search engine visibility and attracting more organic traffic, you can improve your online presence and establish your website as an authority in your industry.
              </li>

            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q.What are meta tags and why are they important for SEO?</h6>
            <p>Meta tags are snippets of HTML code that provide information about a web page to search engines. They typically include a title tag and a meta description tag, which are displayed in search engine results pages. Meta tags are important for SEO because they help search engines understand the content of a page, and can therefore influence how the page is ranked in search results.
            </p>
            <h6>Q.How can I optimize my meta tags for SEO?
            </h6>
            <p>To optimize your meta tags for SEO, you should ensure that your title tag and meta description tag accurately describe the content of the page and include relevant keywords. You should also keep your title tag under 70 characters and your meta description tag under 150 characters. Additionally, you can use the Meta Tags Generator on seotaggenerator.com to create optimized meta tags that comply with SEO best practices.
            </p>
            <h6>Q. What is the Meta Tags Generator on seotaggenerator.com?
            </h6>
            <p>The Meta Tags Generator on seotaggenerator.com is a tool that allows you to create optimized meta tags for your web pages. With just a few clicks, you can generate title tags, meta descriptions, and other important meta tags that comply with SEO best practices.
            </p>
            <h6>Q. What are the benefits of using the Meta Tags Generator on seotaggenerator.com?
              The benefits of using the Meta Tags Generator on seotaggenerator.com include:
            </h6>
            <ul>
              <li>• Saving time and effort in creating optimized meta tags
              </li>
              <li>• Improving your website's search engine visibility
              </li>
              <li>• Attracting more organic traffic to your website
              </li>
              <li>• Complying with SEO best practices for meta tags
              </li>
              <li>• Improving your online presence and reputation</li>
            </ul>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>The Meta Tags Generator on seotaggenerator.com is a valuable tool for improving your website's search engine visibility and attracting more organic traffic. Here are some reasons why you should use it:
            </p>
            <ul>
              <li>• Saves time: Creating optimized meta tags for each web page can be a time-consuming task. The Meta Tags Generator automates the process, allowing you to generate meta tags for all your pages in just a few clicks.</li>
              <li>• Improves SEO: Meta tags are an important factor in SEO, as they provide search engines with information about your website's content. By creating optimized meta tags that comply with SEO best practices, you can improve your website's search engine visibility and attract more organic traffic.
              </li>
              <li>• Easy to use: The Meta Tags Generator on seotaggenerator.com is designed to be user-friendly, even for those with little to no technical knowledge. The tool provides clear instructions and makes it easy to generate meta tags for your web pages.
              </li>
              <li>• Free: The Meta Tags Generator on seotaggenerator.com is completely free to use, making it a cost-effective solution for improving your website's search engine visibility.
              </li>
            </ul>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
