import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, Alert, Container, Label } from "reactstrap"
import Utils from '../Utility';
// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed, registerUserSuccessful, loginUser } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/logo-sm-dark.png";
import { post } from '../../helpers/api_helper';
import Loader from "../../components/Loader";

const Register = props => {

  console.log(props);

  const [registered, setRegistered] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isPassShow, setIsPassShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const { loading } = props;

  // handleValidSubmit
  const handleValidSubmit = (event, values, step) => {
    console.log('im subbmitted', values, step);
    // if (registered)
      // verifyOtp(values);
    // else
      // props.registerUser(values)
  }

  useEffect(() => {
    props.apiError("")
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  useEffect(() => {
    if (props.user)
      setRegistered(true);
  }, [props.user]);

  const verifyOtp = (values) => {
    let body = {
      email: values.email,
      otp: values.otp
    };
    post('/verifyotp', body)
      .then(json => {
        console.log('response from verify otp', json);
        if (json?.statusCode == 200) {
          setVerified(json?.message);
          let body = {
            email: values?.email,
            password: values?.password
          }
          props?.loginUser(body, props.history);
        } else {
          props.registerUserFailed(json?.message);
        }
      })
  }

  const resetForm = () => {
    props.registerUserFailed(null);
    props.registerUserSuccessful(null);
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div> */}
      <Loader visible={loading} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col xl={12}>
              <Card className="overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Free Register</h5>
                    <p className="text-white-50 mb-0">Get your free {Utils.projectName} account now</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  {currentStep == 1 &&
                    <span style={{ color: 'black', fontWeight: 'bold' }}>Section 1 - Personal Details</span>
                  }
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v, currentStep)
                      }}
                    >
                      {/* {registered && !verified ? (
                        <Alert color="success">
                          A verification code has sent to email address!
                        </Alert>
                      ) : null}

                      {verified ? (
                        <Alert color="success">
                          {verified}
                        </Alert>
                      ) : null}

                      {props.registrationError &&
                        props.registrationError ? (
                        <Alert color="danger">
                          {props.registrationError}
                        </Alert>
                      ) : null} */}
                      {currentStep == 1 &&
                        <>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="name"
                                  name="name"
                                  label="Full Name"
                                  className="form-control"
                                  placeholder="Enter name"
                                  type="text"
                                  required
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="fName"
                                  name="fName"
                                  label="Father's Name"
                                  className="form-control"
                                  placeholder="Enter father name"
                                  type="text"
                                  required
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="mName"
                                  name="mName"
                                  label="Mother's Name"
                                  className="form-control"
                                  placeholder="Enter mother name"
                                  type="text"
                                  required
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="adharNo"
                                  name="adharNo"
                                  label="Adhar Number"
                                  className="form-control"
                                  placeholder="Enter Adhar No"
                                  type="number"
                                  required
                                  validate={{ minLength: { value: 12 }, maxLength: { value: 12 } }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="panNo"
                                  label="PAN Number"
                                  type="text"
                                  required
                                  placeholder="Enter pan number"
                                  validate={{ minLength: { value: 10 }, maxLength: { value: 10 } }}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="dob"
                                  name="dob"
                                  label="Date of birth"
                                  className="form-control"
                                  type="date"
                                  required
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="form-group mb-3">
                                <AvRadioGroup id="gender" name="gender" required>
                                  <Label >Gender</Label>
                                  <div className="d-flex" style={{ justifyContent: 'space-around' }}>
                                    <AvRadio label="Male" value="male" />
                                    <AvRadio label="Female" value="famale" />
                                    <AvRadio label="Rather not to say" value="other" />
                                  </div>
                                </AvRadioGroup>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="age"
                                  name="age"
                                  label="Age"
                                  className="form-control"
                                  placeholder="Enter age"
                                  type="number"
                                  required
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  id="email"
                                  name="email"
                                  label="Email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  required
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="mobile"
                                  label="Condidate's Mobile Number"
                                  type="number"
                                  required
                                  placeholder="Enter mobile"
                                  validate={{ minLength: { value: 10 }, maxLength: { value: 10 } }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="mobile"
                                  label="Mobile Number, Residence"
                                  type="number"
                                  required
                                  placeholder="Enter mobile"
                                  validate={{ minLength: { value: 10 }, maxLength: { value: 10 } }}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="address"
                                  label="address"
                                  type="text"
                                  required
                                  placeholder="Enter address"
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="pincode"
                                  label="Pin Code"
                                  type="text"
                                  required
                                  placeholder="Enter 6 digit pin code"
                                  validate={{ minLength: { value: 6 }, maxLength: { value: 6 } }}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="bankName"
                                  label="Bank Name"
                                  type="text"
                                  required
                                  placeholder="Enter Bank name"
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="accountNo"
                                  label="Account Number"
                                  type="text"
                                  required
                                  placeholder="Enter Account Number"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="ifscCode"
                                  label="IFSC Code"
                                  type="text"
                                  required
                                  placeholder="Enter IFSC Code"
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      }

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the {Utils.projectName} {" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>

                  </div>
                </div>
              </Card>
              <div className="mt-5 text-center">
                <p>Already have an account ? <Link onClick={() => { resetForm(); props.history.replace("/login", {}) }} className="fw-medium text-primary">
                  Login</Link> </p>
                <p>© {new Date().getFullYear()} {Utils.projectName} Crafted with <i
                  className="mdi mdi-heart text-danger"></i> by Team Rajmith
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registerUserSuccessful: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
  loginUser: PropTypes.func
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  registerUserSuccessful,
  loginUser
})(Register)
