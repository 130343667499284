import {
  CREATE_COMPANY,
  COMPANY_ERROR,
  COMPANY_SUCCESS,
  UPDATE_COMPANY
} from "./actionTypes"

export const companySuccess = events => ({
  type: COMPANY_SUCCESS,
  payload: events,
})

export const companyError = error => ({
  type: COMPANY_ERROR,
  payload: error,
})

export const createCompany = event => ({
  type: CREATE_COMPANY,
  payload: event,
})

export const updateCompany = event => ({
  type: UPDATE_COMPANY,
  payload: event
})