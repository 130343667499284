import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'

export default function Policy() {
  return (
    <div className='home-page-section'>
    {/* <img src={require('../../assets/images/bg-img.jpg')} className="banner-img-home"  alt="bg-img" /> */}
    <Container fluid>
      <header>
        <div className='logo-h'>
          <Link to="/"><img src={require('../../assets/images/seotaggenerator.png')} alt="seotaggenerator" /></Link>
        </div>
      </header>
    </Container>
    <Container>
    <div className='policy_page'>
        <h3>PRIVACY POLICY</h3>
        <p>At SEO Tag Generator, we take your privacy seriously. We do not require you to provide personal information to use our website, and we do not collect any personal information without your consent.</p>
        <p>We do use cookies to enhance your user experience and collect non-personal information such as your IP address and pages visited. This information is used to improve our website and services.</p>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or to enforce our website policies.</p>
        <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, please be aware that no system is completely secure.</p>
        <p>If you have any questions or concerns about our Privacy Policy, please contact us </p>
        <p>By using our website, you consent to our Privacy Policy and the use of cookies in accordance with our Cookies Policy.</p>
    </div>
    </Container>

  </div>
  )
}
