import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser, getProfile } from "../../store/actions"

const Logout = props => {
  useEffect(() => {
    props.logoutUser(props.history);
    // props.getProfile(undefined);
  })

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
  getProfile: PropTypes.func
}

export default withRouter(connect(null, { logoutUser, getProfile })(Logout))
