import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import React, { useState, useEffect, useRef } from "react"


export default function JsonViewer (props) {

    return(
      <div>
        <JSONInput
          id='a_unique_id'
          placeholder={props?.data}
          locale={locale}
          height='650px'
          viewOnly={true}
        />
      </div>
    )
  }