import PropTypes from 'prop-types'
import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
import Utils from '../Utility'
import Breadcrumb from '../../components/Common/Breadcrumb'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'
const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');
  const [loading, setLoading] = useState(false);
  const [defaultLang, setDefaultLang] = useState(false)
  const langArr = [{ "code": "en", "name": "English", "value": "English" },
  { "code": "ab", "name": "Abkhaz", "value": "аҧсуа" },
  { "code": "aa", "name": "Afar", "value": "Afaraf" },
  { "code": "af", "name": "Afrikaans", "value": "Afrikaans" },
  { "code": "ak", "name": "Akan", "value": "Akan" },
  { "code": "sq", "name": "Albanian", "value": "Shqip" },
  { "code": "am", "name": "Amharic", "value": "አማርኛ" },
  { "code": "ar", "name": "Arabic", "value": "العربية" },
  { "code": "an", "name": "Aragonese", "value": "Aragonés" },
  { "code": "hy", "name": "Armenian", "value": "Հայերեն" },
  { "code": "as", "name": "Assamese", "value": "অসমীয়া" },
  { "code": "av", "name": "Avaric", "value": "авар мацӀ, магӀарул мацӀ" },
  { "code": "ae", "name": "Avestan", "value": "avesta" },
  { "code": "ay", "name": "Aymara", "value": "aymar aru" },
  { "code": "az", "name": "Azerbaijani", "value": "azərbaycan dili" },
  { "code": "bm", "name": "Bambara", "value": "bamanankan" },
  { "code": "ba", "name": "Bashkir", "value": "башҡорт теле" },
  { "code": "eu", "name": "Basque", "value": "euskara, euskera" },
  { "code": "be", "name": "Belarusian", "value": "Беларуская" },
  { "code": "bn", "name": "Bengali", "value": "বাংলা" },
  { "code": "bh", "name": "Bihari", "value": "भोजपुरी" },
  { "code": "bi", "name": "Bislama", "value": "Bislama" },
  { "code": "bs", "name": "Bosnian", "value": "bosanski jezik" },
  { "code": "br", "name": "Breton", "value": "brezhoneg" },
  { "code": "bg", "name": "Bulgarian", "value": "български език" },
  { "code": "my", "name": "Burmese", "value": "ဗမာစာ" },
  { "code": "ca", "name": "Catalan; Valencian", "value": "Català" },
  { "code": "ch", "name": "Chamorro", "value": "Chamoru" },
  { "code": "ce", "name": "Chechen", "value": "нохчийн мотт" },
  { "code": "ny", "name": "Chichewa; Chewa; Nyanja", "value": "chiCheŵa, chinyanja" },
  { "code": "zh", "name": "Chinese", "value": "中文 (Zhōngwén), 汉语, 漢語" },
  { "code": "cv", "name": "Chuvash", "value": "чӑваш чӗлхи" },
  { "code": "kw", "name": "Cornish", "value": "Kernewek" },
  { "code": "co", "name": "Corsican", "value": "corsu, lingua corsa" },
  { "code": "cr", "name": "Cree", "value": "ᓀᐦᐃᔭᐍᐏᐣ" },
  { "code": "hr", "name": "Croatian", "value": "hrvatski" },
  { "code": "cs", "name": "Czech", "value": "česky, čeština" },
  { "code": "da", "name": "Danish", "value": "dansk" },
  { "code": "dv", "name": "Divehi; Dhivehi; Maldivian;", "value": "ދިވެހި" },
  { "code": "nl", "name": "Dutch", "value": "Nederlands, Vlaams" },
  { "code": "eo", "name": "Esperanto", "value": "Esperanto" },
  { "code": "et", "name": "Estonian", "value": "eesti, eesti keel" },
  { "code": "ee", "name": "Ewe", "value": "Eʋegbe" },
  { "code": "fo", "name": "Faroese", "value": "føroyskt" },
  { "code": "fj", "name": "Fijian", "value": "vosa Vakaviti" },
  { "code": "fi", "name": "Finnish", "value": "suomi, suomen kieli" },
  { "code": "fr", "name": "French", "value": "français, langue française" },
  { "code": "ff", "name": "Fula; Fulah; Pulaar; Pular", "value": "Fulfulde, Pulaar, Pular" },
  { "code": "gl", "name": "Galician", "value": "Galego" },
  { "code": "ka", "name": "Georgian", "value": "ქართული" },
  { "code": "de", "name": "German", "value": "Deutsch" },
  { "code": "el", "name": "Greek, Modern", "value": "Ελληνικά" },
  { "code": "gn", "name": "Guaraní", "value": "Avañeẽ" },
  { "code": "gu", "name": "Gujarati", "value": "ગુજરાતી" },
  { "code": "ht", "name": "Haitian; Haitian Creole", "value": "Kreyòl ayisyen" },
  { "code": "ha", "name": "Hausa", "value": "Hausa, هَوُسَ" },
  { "code": "he", "name": "Hebrew (modern)", "value": "עברית" },
  { "code": "hz", "name": "Herero", "value": "Otjiherero" },
  { "code": "hi", "name": "Hindi", "value": "हिन्दी, हिंदी" },
  { "code": "ho", "name": "Hiri Motu", "value": "Hiri Motu" },
  { "code": "hu", "name": "Hungarian", "value": "Magyar" },
  { "code": "ia", "name": "Interlingua", "value": "Interlingua" },
  { "code": "id", "name": "Indonesian", "value": "Bahasa Indonesia" },
  { "code": "ie", "name": "Interlingue", "value": "Originally called Occidental; then Interlingue after WWII" },
  { "code": "ga", "name": "Irish", "value": "Gaeilge" },
  { "code": "ig", "name": "Igbo", "value": "Asụsụ Igbo" },
  { "code": "ik", "name": "Inupiaq", "value": "Iñupiaq, Iñupiatun" },
  { "code": "io", "name": "Ido", "value": "Ido" },
  { "code": "is", "name": "Icelandic", "value": "Íslenska" },
  { "code": "it", "name": "Italian", "value": "Italiano" },
  { "code": "iu", "name": "Inuktitut", "value": "ᐃᓄᒃᑎᑐᑦ" },
  { "code": "ja", "name": "Japanese", "value": "日本語 (にほんご／にっぽんご)" },
  { "code": "jv", "name": "Javanese", "value": "basa Jawa" },
  { "code": "kl", "name": "Kalaallisut, Greenlandic", "value": "kalaallisut, kalaallit oqaasii" },
  { "code": "kn", "name": "Kannada", "value": "ಕನ್ನಡ" },
  { "code": "kr", "name": "Kanuri", "value": "Kanuri" },
  { "code": "ks", "name": "Kashmiri", "value": "कश्मीरी, كشميري‎" },
  { "code": "kk", "name": "Kazakh", "value": "Қазақ тілі" },
  { "code": "km", "name": "Khmer", "value": "ភាសាខ្មែរ" },
  { "code": "ki", "name": "Kikuyu, Gikuyu", "value": "Gĩkũyũ" },
  { "code": "rw", "name": "Kinyarwanda", "value": "Ikinyarwanda" },
  { "code": "ky", "name": "Kirghiz, Kyrgyz", "value": "кыргыз тили" },
  { "code": "kv", "name": "Komi", "value": "коми кыв" },
  { "code": "kg", "name": "Kongo", "value": "KiKongo" },
  { "code": "ko", "name": "Korean", "value": "한국어 (韓國語), 조선말 (朝鮮語)" },
  { "code": "ku", "name": "Kurdish", "value": "Kurdî, كوردی‎" },
  { "code": "kj", "name": "Kwanyama, Kuanyama", "value": "Kuanyama" },
  { "code": "la", "name": "Latin", "value": "latine, lingua latina" },
  { "code": "lb", "name": "Luxembourgish, Letzeburgesch", "value": "Lëtzebuergesch" },
  { "code": "lg", "name": "Luganda", "value": "Luganda" },
  { "code": "li", "name": "Limburgish, Limburgan, Limburger", "value": "Limburgs" },
  { "code": "ln", "name": "Lingala", "value": "Lingála" },
  { "code": "lo", "name": "Lao", "value": "ພາສາລາວ" },
  { "code": "lt", "name": "Lithuanian", "value": "lietuvių kalba" },
  { "code": "lu", "name": "Luba-Katanga", "value": "" },
  { "code": "lv", "name": "Latvian", "value": "latviešu valoda" },
  { "code": "gv", "name": "Manx", "value": "Gaelg, Gailck" },
  { "code": "mk", "name": "Macedonian", "value": "македонски јазик" },
  { "code": "mg", "name": "Malagasy", "value": "Malagasy fiteny" },
  { "code": "ms", "name": "Malay", "value": "bahasa Melayu, بهاس ملايو‎" },
  { "code": "ml", "name": "Malayalam", "value": "മലയാളം" },
  { "code": "mt", "name": "Maltese", "value": "Malti" },
  { "code": "mi", "name": "Māori", "value": "te reo Māori" },
  { "code": "mr", "name": "Marathi (Marāṭhī)", "value": "मराठी" },
  { "code": "mh", "name": "Marshallese", "value": "Kajin M̧ajeļ" },
  { "code": "mn", "name": "Mongolian", "value": "монгол" },
  { "code": "na", "name": "Nauru", "value": "Ekakairũ Naoero" },
  { "code": "nv", "name": "Navajo, Navaho", "value": "Diné bizaad, Dinékʼehǰí" },
  { "code": "nb", "name": "Norwegian Bokmål", "value": "Norsk bokmål" },
  { "code": "nd", "name": "North Ndebele", "value": "isiNdebele" },
  { "code": "ne", "name": "Nepali", "value": "नेपाली" },
  { "code": "ng", "name": "Ndonga", "value": "Owambo" },
  { "code": "nn", "name": "Norwegian Nynorsk", "value": "Norsk nynorsk" },
  { "code": "no", "name": "Norwegian", "value": "Norsk" },
  { "code": "ii", "name": "Nuosu", "value": "ꆈꌠ꒿ Nuosuhxop" },
  { "code": "nr", "name": "South Ndebele", "value": "isiNdebele" },
  { "code": "oc", "name": "Occitan", "value": "Occitan" },
  { "code": "oj", "name": "Ojibwe, Ojibwa", "value": "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  { "code": "cu", "name": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", "value": "ѩзыкъ словѣньскъ" },
  { "code": "om", "name": "Oromo", "value": "Afaan Oromoo" },
  { "code": "or", "name": "Oriya", "value": "ଓଡ଼ିଆ" },
  { "code": "os", "name": "Ossetian, Ossetic", "value": "ирон æвзаг" },
  { "code": "pa", "name": "Panjabi, Punjabi", "value": "ਪੰਜਾਬੀ, پنجابی‎" },
  { "code": "pi", "name": "Pāli", "value": "पाऴि" },
  { "code": "fa", "name": "Persian", "value": "فارسی" },
  { "code": "pl", "name": "Polish", "value": "polski" },
  { "code": "ps", "name": "Pashto, Pushto", "value": "پښتو" },
  { "code": "pt", "name": "Portuguese", "value": "Português" },
  { "code": "qu", "name": "Quechua", "value": "Runa Simi, Kichwa" },
  { "code": "rm", "name": "Romansh", "value": "rumantsch grischun" },
  { "code": "rn", "name": "Kirundi", "value": "kiRundi" },
  { "code": "ro", "name": "Romanian, Moldavian, Moldovan", "value": "română" },
  { "code": "ru", "name": "Russian", "value": "русский язык" },
  { "code": "sa", "name": "Sanskrit (Saṁskṛta)", "value": "संस्कृतम्" },
  { "code": "sc", "name": "Sardinian", "value": "sardu" },
  { "code": "sd", "name": "Sindhi", "value": "सिन्धी, سنڌي، سندھی‎" },
  { "code": "se", "name": "Northern Sami", "value": "Davvisámegiella" },
  { "code": "sm", "name": "Samoan", "value": "gagana faa Samoa" },
  { "code": "sg", "name": "Sango", "value": "yângâ tî sängö" },
  { "code": "sr", "name": "Serbian", "value": "српски језик" },
  { "code": "gd", "name": "Scottish Gaelic; Gaelic", "value": "Gàidhlig" },
  { "code": "sn", "name": "Shona", "value": "chiShona" },
  { "code": "si", "name": "Sinhala, Sinhalese", "value": "සිංහල" },
  { "code": "sk", "name": "Slovak", "value": "slovenčina" },
  { "code": "sl", "name": "Slovene", "value": "slovenščina" },
  { "code": "so", "name": "Somali", "value": "Soomaaliga, af Soomaali" },
  { "code": "st", "name": "Southern Sotho", "value": "Sesotho" },
  { "code": "es", "name": "Spanish; Castilian", "value": "español, castellano" },
  { "code": "su", "name": "Sundanese", "value": "Basa Sunda" },
  { "code": "sw", "name": "Swahili", "value": "Kiswahili" },
  { "code": "ss", "name": "Swati", "value": "SiSwati" },
  { "code": "sv", "name": "Swedish", "value": "svenska" },
  { "code": "ta", "name": "Tamil", "value": "தமிழ்" },
  { "code": "te", "name": "Telugu", "value": "తెలుగు" },
  { "code": "tg", "name": "Tajik", "value": "тоҷикӣ, toğikī, تاجیکی‎" },
  { "code": "th", "name": "Thai", "value": "ไทย" },
  { "code": "ti", "name": "Tigrinya", "value": "ትግርኛ" },
  { "code": "bo", "name": "Tibetan Standard, Tibetan, Central", "value": "བོད་ཡིག" },
  { "code": "tk", "name": "Turkmen", "value": "Türkmen, Түркмен" },
  { "code": "tl", "name": "Tagalog", "value": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { "code": "tn", "name": "Tswana", "value": "Setswana" },
  { "code": "to", "name": "Tonga (Tonga Islands)", "value": "faka Tonga" },
  { "code": "tr", "name": "Turkish", "value": "Türkçe" },
  { "code": "ts", "name": "Tsonga", "value": "Xitsonga" },
  { "code": "tt", "name": "Tatar", "value": "татарча, tatarça, تاتارچا‎" },
  { "code": "tw", "name": "Twi", "value": "Twi" },
  { "code": "ty", "name": "Tahitian", "value": "Reo Tahiti" },
  { "code": "ug", "name": "Uighur, Uyghur", "value": "Uyƣurqə, ئۇيغۇرچە‎" },
  { "code": "uk", "name": "Ukrainian", "value": "українська" },
  { "code": "ur", "name": "Urdu", "value": "اردو" },
  { "code": "uz", "name": "Uzbek", "value": "zbek, Ўзбек, أۇزبېك‎" },
  { "code": "ve", "name": "Venda", "value": "Tshivenḓa" },
  { "code": "vi", "name": "Vietnamese", "value": "Tiếng Việt" },
  { "code": "vo", "name": "Volapük", "value": "Volapük" },
  { "code": "wa", "name": "Walloon", "value": "Walon" },
  { "code": "cy", "name": "Welsh", "value": "Cymraeg" },
  { "code": "wo", "name": "Wolof", "value": "Wollof" },
  { "code": "fy", "name": "Western Frisian", "value": "Frysk" },
  { "code": "xh", "name": "Xhosa", "value": "isiXhosa" },
  { "code": "yi", "name": "Yiddish", "value": "ייִדיש" },
  { "code": "yo", "name": "Yoruba", "value": "Yorùbá" },
  { "code": "za", "name": "Zhuang, Chuang", "value": "Saɯ cueŋƅ, Saw cuengh" }
  ]

  const countryArr = [
    { name: 'India', code: 'IN' },
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
  ]

  const [data, setData] = useState({
    domainurl: '',
    allowallRobo: 'Allow',
    allowRuleVal: 'Allow',
    sitemapurl: '',
    language: langArr[0].code,
    languageName: langArr[0].value,
    country: countryArr[0].code,
    countryName: countryArr[0].name,
    robot: 'All',
    domainArr: []
  });

  const [predata, setPreData] = useState({})

  const handleChange = (e) => {
    console.log('==>>', e.target.name, " <==  ");
    setData({ ...data, [e.target.name]: e.target.value ? e.target.value.trimStart() : "" })
  }

  const deleteAction = (index) => {
    // console.log(index," = ", data.RuleArr.length , ",    data before delete=>", data.RuleArr)
    let temp = Object.assign({}, data);
    temp.domainArr.splice(index, 1)
    console.log("data =>", temp.domainArr)
    setData(temp);
  }

  const updateArr = (e, index, item) => {
    var name = e.target.name
    var value = e.target.value

    let temp = Object.assign([], data.domainArr)
    console.log(name, ' <=> ', item, " - ", value, " <=>", temp)

    if (name == "domainurl") {
      temp[index].domainurl = value
    }
    if (name == "country") {
      temp[index].country = value
    }
    if (name == "language") {
      temp[index].language = value
    }
    setData({ ...data, domainArr: temp })
  }
  const setItemDefault = (item, index) => {

    let temp = Object.assign([], data.domainArr)
    console.log('<=> ', item, " - ", " <=>", temp)

    temp[index].defaultLang = !item?.defaultLang
    // alert('okok')

    setData({ ...data, domainArr: temp })

  }
  const handleValidSubmit = () => {
    addDomain()
  }


  const setCountry = (e) => {
    console.log('==>>', e.target)
    // return;
    setData({ ...data, country: e.target.country, countryName: countryArr[e.target.selectedIndex].name })
  }
  const setLanguage = (e) => {
    console.log('==>>', e.target)
    setData({ ...data, language: e.target.language, languageName: langArr[e.target.selectedIndex].name })
  }




  const addDomain = () => {
    if (data.domainurl) {
      var arr = data.domainArr
      let temp = {}
      temp.domainurl = data.domainurl
      temp.language = data.language
      temp.country = data.country
      temp.defaultLang = defaultLang
      // temp[index].action = data.allowRobo
      data.domainArr.push(temp)
      console.log('======= ', temp, "  ", JSON.stringify(data.domainArr))
      setData({ ...data, domainArr: arr, domainurl: '', language: '', country: '', })
    }
  }

  const CopyURL = () => {
    let link = ""
    data.domainArr?.map((item, index) => {
      link += `<link rel="alternate" hreflang="${item.defaultLang ? "x-default" : item?.language + '-' + item?.country}" href="${item.domainurl}/> \n`
    })
    navigator.clipboard.writeText(link)
  }


  return (
    <React.Fragment>
      <Helmet>
        <title>Hreflang Tag Generator Tool - SeoTagGenerator</title>
        <meta name="description" content="Ensure your website is correctly targeting international audiences with our Hreflang Tag Generator. Generate tags easily following Google's specification and improve your SEO by providing accurate language and regional targeting. Reach a wider audience and avoid duplicate content issues with ease. Try it now on seotaggenerator.com." />
        <meta name="keywords" content="hreflang tag generator tool, hreflang tag generator, hreflang tag in html" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/hreflang" />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT="hreflang tag generator tool, hreflang tag generator, hreflang tag in html" />
        <meta name="dc.description" CONTENT=" Ensure your website is correctly targeting international audiences with our Hreflang Tag Generator. Generate tags easily following Google's specification and improve your SEO by providing accurate language and regional targeting. Reach a wider audience and avoid duplicate content issues with ease. Try it now on seotaggenerator.com" />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Hreflang Tag Generator Tool - SeoTagGenerator" />
        <meta property="og:description" content="Ensure your website is correctly targeting international audiences with our Hreflang Tag Generator. Generate tags easily following Google's specification and improve your SEO by providing accurate language and regional targeting. Reach a wider audience and avoid duplicate content issues with ease. Try it now on seotaggenerator.com" />
        <meta property="og:url" content="https://www.seotaggenerator.com/hreflang" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="Hreflang Tag Generator Tool - SeoTagGenerator" />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Ensure your website is correctly targeting international audiences with our Hreflang Tag Generator. Generate tags easily following Google's specification and improve your SEO by providing accurate language and regional targeting. Reach a wider audience and avoid duplicate content issues with ease. Try it now on seotaggenerator.com" />
        <link rel="canonical" href="https://www.seotaggenerator.com/hreflang" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          {/* <h1>Hreflang Tag Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="Hreflang Tag Generator" />
          <Row>
            <Col md={7}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}

                    <div>
                      <Row className="mt-3" style={{ alignItems: 'center' }}>
                        <Col md={4}>
                          <div className="mb-3">
                            <AvField
                              name="domainurl"
                              label="Domain/URL"
                              className="form-control"
                              placeholder="Sitemap URL"
                              value={data.domainurl}
                              onChange={(e) => handleChange(e)}
                              type="url"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <AvField
                              name="language"
                              label={"Language"}
                              className="form-control"
                              placeholder=""
                              type="select"
                              value={data.languageName ? data.languageName : langArr[0].value}
                              onChange={(e) => setLanguage(e)}
                              required
                            >
                              {langArr?.map((item, index) => {
                                return (
                                  <option value={item.value}>{item.value}</option>
                                )
                              })}
                            </AvField>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <AvField
                              name="country"
                              label={"Region (optional)"}
                              className="form-control"
                              placeholder=""
                              type="select"
                              value={data.countryName ? data.countryName : 'All'}
                              onChange={(e) => setCountry(e)}
                            >
                              {countryArr?.map((item, index) => {
                                return (
                                  <option value={item}>{item.name}</option>
                                )
                              })}
                            </AvField>
                          </div>
                        </Col>
                        <Col md={2} a>
                          <div className='d-flex justify-content-around'>
                            <button
                              className="btn left_btn mt-20"
                              type="submit"
                              onClick={() => setDefaultLang(!defaultLang)}
                            >
                              {defaultLang ?
                                <i className='mdi mdi-check' />
                                :
                                <i className='mdi mdi-close' />
                              }
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {data.domainArr?.map((item, index) => {
                          return (
                            <div>
                              <Row className="mt-3" style={{ alignItems: 'center' }}>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <AvField
                                      name="domainurl"
                                      label="Domain/URL"
                                      className="form-control"
                                      placeholder="Sitemap URL"
                                      value={item.domainurl}
                                      onChange={(e) => updateArr(e)}
                                      type="url"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <AvField
                                      name="language"
                                      label={"Language"}
                                      className="form-control"
                                      placeholder=""
                                      type="select"
                                      value={item.language ? item.language : 'All'}
                                      onChange={(e) => updateArr(e, index)}

                                    >
                                      {langArr?.map((item, index) => {
                                        return (
                                          <option value={item.value}>{item.value}</option>
                                        )
                                      })}
                                    </AvField>
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <AvField
                                      name="country"
                                      label={"Country"}
                                      className="form-control"
                                      placeholder=""
                                      type="select"
                                      value={item.country ? item.country : ''}
                                      onChange={(e) => updateArr(e, index)}

                                    >
                                      {countryArr?.map((item, index) => {
                                        return (
                                          <option value={item.code}>{item.name}</option>
                                        )
                                      })}
                                    </AvField>
                                  </div>
                                </Col>
                                <Col md={1} a>
                                  <div className='d-flex justify-content-around'>
                                    <button
                                      className="btn left_btn mt-20"
                                      type="submit"
                                      name='button'
                                      onClick={(e) => setItemDefault(item, index)}
                                    >
                                      {item.defaultLang ?
                                        <i className='mdi mdi-check' />
                                        :
                                        <i className='mdi mdi-close' />
                                      }
                                    </button>
                                  </div>
                                </Col>
                                <Col md={2} a>
                                  <div className='d-flex justify-content-around'>
                                    <button
                                      className="btn right_btn mt-20"
                                      type="submit"
                                      onClick={() => deleteAction(index)}
                                    >

                                      <i className='mdi mdi-minus' />

                                    </button>
                                  </div>
                                </Col>
                              </Row>

                            </div>
                          )
                        })}
                        <Col md={6}>
                          <div className="mt-4">
                            <button
                              className="btn left_btn waves-effect waves-light"
                              type="submit"
                            // onClick={() => addDomain()}
                            >
                              + ADD DOMAIN OR URL
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </div>

                  </div>

                </CardBody>
              </Card>
            </Col>
            <Col md={5}>
              <div id="MyFile" style={{ backgroundColor: 'black', padding: 20, borderRadius: "0px", }}>
                {data.domainArr?.map((item, index) => {
                  return (
                    <p style={{ color: '#9CDCFE' }}>{`<link rel=`}<span style={{ color: '#CE8453' }}>"alternate"</span> hreflang=<span style={{ color: '#CE8453' }}>"{item.defaultLang ? "x-default" : item?.language + '-' + item?.country}"<span style={{ color: '#9CDCFE' }}>href=</span>"{item.domainurl}" </span>{`/>`}</p>
                  )
                })}
              </div>
              <div className='d-flex btn_section mt-4'>
                <button
                  className="btn right_btn w-50  mt-20"
                  type="button"
                  onClick={() => CopyURL()}
                >
                  Copy
                </button>
              </div>

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>If you're looking to expand your website's reach to a global audience, using hreflang tags is an essential part of your SEO strategy. However, creating these tags manually can be a time-consuming and tedious process, especially if your website has multiple language versions or regional content variations. That's where SEOTagGenerator.com's Hreflang Tag Generator comes in.

            </p>
            <p>Our Hreflang Tag Generator is a powerful tool that helps website owners create and implement hreflang tags easily and quickly. With just a few clicks, you can generate accurate hreflang tags for each of your web pages, ensuring that search engines understand the language and regional targeting of your content.
            </p>
            <p>Using hreflang tags correctly can have a significant impact on your website's search engine visibility and user experience. By providing search engines with the correct language and regional signals, you can improve your website's ranking in relevant search results and ensure that users find the content they're looking for.
            </p>
            <p>At SEOTagGenerator.com, we're committed to helping website owners succeed in the competitive world of SEO. Our Hreflang Tag Generator is just one of the many tools we offer to help our users improve their website's search engine visibility and reach a global audience.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>SEOTagGenerator.com's Hreflang Tag Generator is a valuable tool for website owners who are looking to expand their reach to a global audience. Using hreflang tags is a crucial aspect of search engine optimization, and our generator offers several benefits to website owners, including:</p>
            <ul>
              <li>• Improved search engine visibility: Hreflang tags inform search engines which version of a page is relevant to a particular region or language, increasing the visibility of your content to a wider audience.
              </li>
              <li>• Enhanced user experience: By directing users to the appropriate version of a page based on their language and location, hreflang tags can create a more personalized and seamless user experience.
              </li>
              <li>• Increased traffic: By targeting specific language and region-specific audiences, hreflang tags can attract more traffic to your website from those specific audiences.
              </li>
              <li>• Time-saving: Manually creating hreflang tags can be time-consuming and error-prone, but our Hreflang Tag Generator simplifies the process, allowing you to create accurate and error-free tags quickly and easily.
              </li>
              <li>• Error-free implementation: Our Hreflang Tag Generator ensures that the generated hreflang tags are accurate, reducing the risk of incorrect implementation and potential negative impact on your website's SEO.
              </li>
              <p className='mt-3'>
                SEOTagGenerator.com's Hreflang Tag Generator is a reliable and efficient tool that can help website owners improve their website's search engine visibility, enhance user experience, attract more traffic, save time, and ensure accurate implementation of hreflang tags.

              </p>
            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What is a hreflang tag?
            </h6>
            <p>A. A hreflang tag is a piece of HTML code that signals to search engines which version of a page to display to users based on their language and geographic location.

            </p>
            <h6>Q. Why is using hreflang tags important?
            </h6>
            <p>A. Using hreflang tags is important because it helps search engines understand which version of a page to show to users based on their language and location, improving search engine visibility and user experience.
            </p>
            <h6>Q. How can SEOTagGenerator.com's Hreflang Tag Generator benefit my website?
            </h6>
            <p>A. Our Hreflang Tag Generator can benefit your website by simplifying the process of creating accurate and error-free hreflang tags, improving search engine visibility, enhancing user experience, attracting more traffic, and saving time.
            </p>
            <h6>Q. How do I use SEOTagGenerator.com's Hreflang Tag Generator?
            </h6>
            <p>A. To use our Hreflang Tag Generator, simply enter your website URL and select the languages and countries you wish to target. The tool will then generate accurate and error-free hreflang tags for your website.
            </p>
            <h6>Q. Is SEOTagGenerator.com's Hreflang Tag Generator free to use?
            </h6>
            <p>A. Yes, our Hreflang Tag Generator is completely free to use, and you can generate unlimited hreflang tags for your website.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>SEOTagGenerator.com's Hreflang Tag Generator is an essential tool for website owners who want to expand their reach and attract a global audience. By using our free and user-friendly tool, you can easily create accurate and error-free hreflang tags that target the right audience based on their language and geographic location. This can lead to improved search engine visibility, increased traffic, and higher engagement rates, ultimately resulting in greater success for your online business. With SEOTagGenerator.com's Hreflang Tag Generator, you can take control of your website's international optimization and confidently reach a broader audience. So why wait? Start using our powerful tool today and unlock the full potential of your online presence!</p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
