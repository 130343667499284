import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import '../../assets/css/style.css'
import Footer from '../../components/HorizontalLayout/Footer'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Utils from '../Utility'
import { Helmet } from "react-helmet-async";

const Home=()=>{

  return (
    <>
      <Helmet>
        <title> Free SEO Tag Generator Tool - SeoTagGenerator</title>
        <meta data-react-helmet="true" name="description" content="SeoTagGenerator is the best free SEO tools for Article Schema Generator, Local Business Schema, Citation, Preview Title Tag, and more!" />
        <meta data-react-helmet="true" name="keywords" content="Free SEO Tag Generator Tool, hreflang tag generator, Free Breadcrumbs Generator Tool, hCard generator, Schema Markup Generator" />
        <meta data-react-helmet="true" name="dc.language" CONTENT="English" />
        <meta data-react-helmet="true" name="dc.source" CONTENT="https://www.seotaggenerator.com" />
        <meta data-react-helmet="true" name="dc.title" CONTENT=" SeoTagGenerator " />
        <meta data-react-helmet="true" name="dc.keywords" CONTENT=" Free SEO Tag Generator Tool, hreflang tag generator, Free Breadcrumbs Generator Tool, hCard generator, Schema Markup Generator" />
        <meta data-react-helmet="true" name="dc.description" CONTENT="SeoTagGenerator is the best free SEO tools for Article Schema Generator, Local Business Schema, Citation, Preview Title Tag, and more!" />
        <meta data-react-helmet="true" name="geo.region" content="IN" />
        <meta data-react-helmet="true" name="geo.placename" content="Gurgaon" />
        <meta data-react-helmet="true" name="Author" content=" SeoTagGenerator " />
        <meta data-react-helmet="true" name="robots" content="index, follow" />
        <meta data-react-helmet="true" property="og:locale" content="en-in" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta data-react-helmet="true" property="og:title" content="Free SEO Tag Generator Tool - SeoTagGenerator" />
        <meta data-react-helmet="true" property="og:description" content=" SeoTagGenerator is the best free SEO tools for Article Schema Generator, Local Business Schema, Citation, Preview Title Tag, and more!" />
        <meta data-react-helmet="true" property="og:url" content="https://www.seotaggenerator.com" />
        <meta data-react-helmet="true" property="og:site_name" content="seotaggenerator.com" />
        <meta data-react-helmet="true" name="twitter:card" content="summary" />
        <meta data-react-helmet="true" property="twitter:title" content="Schema Markup Generator - SeoTagGenerator" />
        <meta data-react-helmet="true" name="twitter:image" content="" />
        <meta data-react-helmet="true" property="twitter:description" content=" SeoTagGenerator is the best free SEO tools for Article Schema Generator, Local Business Schema, Citation, Preview Title Tag, and more." />
        <link rel="canonical" href="https://www.seotaggenerator.com/" />

      </Helmet>
      <div className='home-page-section'>
        {/* <img src={require('../../assets/images/bg-img.jpg')} className="banner-img-home"  alt="bg-img" /> */}
        <Container fluid>
          <header>
            <div className='logo-h'>
              <Link to="/"><img src={require('../../assets/images/seotaggenerator.png')} alt="seotaggenerator" /></Link>
            </div>
          </header>
        </Container>
        <div className='banner-content'>
          <h1>Try the best free SEO tools for Article Schema Generator,<br /> Local Business Schema, Citation, Preview Title Tag, and more!</h1>
        </div>
        <div className='tool-section'>
          <Container fluid>
            <Row className='justify-content-center'>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/schema">Generate Schema</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/twittercard">Twitter Card Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/robotstxt">Robots txt Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/hreflang">Hreflang Tag Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/hcard">H Card</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/ogtag">Open Graph Tag Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/breadcrumbs">Breadcrumbs Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/metatag">Meta Tags Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/passgenerator">Password Generator</Link>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12}>
                <div className='item-box-link'>
                  <Link to="/utmurl">UTM URL Generator</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='home-footer'>
        <footer className="footer">
          <Footer />
          <hr className="footer_hr" />
          <div className="container-fluid">
            <div className="footer-content padding_60px" id='about'>
              <h5>Introduction</h5>
              <p>Welcome to SEO Tag Generator, the ultimate one-stop destination for all your SEO-related needs. Our website offers a wide range of free and powerful tools and resources designed to help you improve your website's SEO and increase your online visibility.
              </p>
              <p>With our easy-to-use tools, you can generate optimized title tags and meta descriptions, create Article Schema to help search engines better understand your content, and even generate Local Business Schema to help your business show up in local search results.
              </p>
              <p>Our Citation tool helps you build backlinks to your website, while our Preview Title Tag tool allows you to preview how your website will appear in search results. We also offer a range of other useful SEO tools and resources to help you optimize your website and improve your online presence.
              </p>
              <p>Whether you're a business owner, a marketer, or a website developer, our powerful tools and resources can help you take your website's SEO to the next level. So why wait? Try SEO Tag Generator today and start optimizing your website for success!</p>
            </div>
            <div className="footer-content padding_60px">
              <h5>Benefits</h5>
              <p>SEO Tag Generator offers a range of powerful tools designed to help you optimize your website's SEO and improve your online visibility.
              </p>
              <p>Our Article Schema Generator, Local Business Schema, and Citation tools can all help improve your website's search rankings and drive targeted traffic to your site. Meanwhile, our Preview Title Tag tool and UTM URL generator can help you create compelling titles and URLs that are optimized for search engines and social media platforms.
              </p>
              <p>We also offer tools such as the hreflang tag generator and open graph generator, which can help ensure your website is properly optimized for international audiences and social media sharing. And our strong password generator can help you create secure passwords to protect your website and online accounts.
              </p>
              <p>Overall, our range of SEO tools can help you optimize your website and improve your online presence, making it easier for your target audience to find you online.
              </p>
            </div>
            <div className="footer-content padding_60px" id='faq'>
              <h5>FAQs</h5>
              <h6>Q: What is SEO Tag Generator?
              </h6>
              <p>A: SEO Tag Generator is a website that offers a range of free SEO tools to help you optimize your website's SEO and improve your online visibility.
              </p>
              <h6>Q: Are the tools on SEO Tag Generator really free?
              </h6>
              <p>A: Yes, all of the SEO tools on SEO Tag Generator are completely free to use.
              </p>
              <h6>Q: Do I need any technical skills to use the tools on SEO Tag Generator?
              </h6>
              <p>A: No, our SEO tools are designed to be user-friendly and accessible to anyone, regardless of technical expertise.
              </p>
              <h6>Q: How can the tools on SEO Tag Generator help my website's SEO?
              </h6>
              <p>A: Our SEO tools can help improve your website's search rankings, drive targeted traffic to your site, and optimize your website for social media platforms.
              </p>
              <h6>Q: Is my data safe when using SEO Tag Generator's tools?
              </h6>
              <p>A: Yes, we take data privacy and security very seriously. We do not collect any personal information from users of our SEO tools, and all data entered into our tools is securely encrypted.
              </p>
            </div>
            <div className="footer-content padding_60px">
              <h5>Why should you use it?</h5>
              <p>1. Free SEO Tools: SEO Tag Generator offers a range of powerful SEO tools that are completely free to use, making it an accessible option for website owners on a tight budget.
              </p>
              <p>2. User-Friendly Interface: Our website has a user-friendly interface that makes it easy for anyone to access and use our SEO tools without requiring any technical skills or experience.
              </p>
              <p>3. Range of Tools: We offer a wide range of SEO tools, including Article Schema Generator, Local Business Schema, Citation, Preview Title Tag, UTM URL generator, hreflang tag generator, open graph generator, and even a strong password generator.</p>
              <p>4. Improve SEO: Our SEO tools can help improve your website's search rankings, drive targeted traffic to your site, and optimize your website for social media platforms.
              </p>
              <p>5. Data Privacy and Security: We take data privacy and security very seriously, and we do not collect any personal information from users of our SEO tools. All data entered into our tools is securely encrypted.
              </p>
              <p>6. Saves Time and Effort: By using our SEO tools, you can save time and effort on manually optimizing your website for SEO, leaving you with more time to focus on other aspects of your website and business.</p>
            </div>
          </div>


          <hr className="footer_hr" />
          <Disclaimer />

        </footer>
      </div>
    </>
  )
}

export default Home;
