import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  CREATE_COMPANY,
  UPDATE_COMPANY
} from "./actionTypes"
import {
  companyError, companySuccess
} from "./actions";

import { profileSuccess } from '../auth/profile/actions';

//Include Both Helper File with needed methods
import {
  addCompany, postJwtProfile, editCompany
} from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";

function* createCompany({ payload: event }) {
  try {
    const response = yield call(addCompany, event)
    if (response?.statusCode == 200) {
      // toast.success(response?.message);
      yield put(companySuccess(response?.company));
      const responseProfile = yield call(postJwtProfile, { user_id: event?.addedBy, token: event?.token })
      // console.log('response after add company of profile', responseProfile)
      if (responseProfile?.statusCode == 200) {
        yield put(profileSuccess({ ...responseProfile?.user, token: event?.token }));
        toast.success(response.message);
      }
    } else {
      if (response?.error) {
        toast.error('Failed '+JSON.stringify(response?.error?.keyValue));
      } else
        toast.error(response?.message || 'Something Went Wrong');
      throw response?.message || 'Something Went Wrong';
    }
  } catch (error) {
    yield put(companyError(error))
  }
}

function* updateCompany({ payload: event }) {
  try {
    const toastId = 'update';
    // console.log('hitting update company', event)
    const response = yield call(editCompany, event);
    // console.log('response from update company', response);
    if (response && response.statusCode == 200) {
      yield put(companySuccess(event));
      // console.log('calling get profile after company update');
      const responseProfile = yield call(postJwtProfile, { user_id: event?.addedBy, token: event?.token });
      // console.log('response after update company of profile', responseProfile)
      if (responseProfile?.statusCode == 200) {
        localStorage.setItem("authUser", JSON.stringify({ ...responseProfile?.user, token: event?.token }));
        yield put(profileSuccess({ ...responseProfile?.user, token: event?.token }));
        // console.log('is toast active', toast.isActive(toastId));
        if (!toast.isActive(toastId)) {
          toastId = toast.success(response?.message, {
            toastId: toastId
          });
        }
      }
    } else
      throw response?.message;
  } catch (error) {
    toast.error(error);
    yield put(companyError(error))
  }
}

function* companySaga() {
  yield takeEvery(CREATE_COMPANY, createCompany)
  yield takeEvery(UPDATE_COMPANY, updateCompany)
}

export default companySaga
