import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';

import JSONInput from 'react-json-editor-ajrm';
    import locale    from 'react-json-editor-ajrm/locale/en';

const Leads = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  // const []

  const [data, setData] = useState({
    "context": "https://www.rajmith.com/",   //url
    "type": "Article",
    "id":'',
    "headline": "",
    "description": "",
    "imagetype": "ImageObject",
    "imageurl": "",
    "imagewidth": "",
    "imageheight": "",
    "authortype": "Person",
    "authorname": "",
    "publishertype": "Organization",
    "publishername": "",
    "publisherlogotype": "ImageObject",
    "publisherlogourl": "",
    "publisherlogowidth": "",
    "publisherlogoheight": "",
    "datePublished": "",
    "dateModified": ""
  });

  const [predata, setPreData] = useState(
    {
      "@context": '',
      "@type": "",
      "mainEntityOfPage": {
        "@type": "",
        "@id": ""
      },
      "headline": "",
      "description": "",
      "image": {
        "@type": "",
        "url": "",
        "width": "",
        "height": ""
      },
      "author": {
        "@type": "Organization",
        "name": ""
      },
      "publisher": {
        "@type": "Organization",
        "name": "",
        "logo": {
          "@type": "",
          "url": "",
          "width": "",
          "height": ""
        }
      },
      "datePublished": "",
      "dateModified": ""
    }
  )

  useEffect(()=>{
    setPreData({
                  "@context": data?.context ? data?.context : "",
                  "@type": data?.type ? data?.type : "",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": data.id ? data.id : "",
                  },
                  "headline": data?.headline ? data?.headline : "",
                  "description": data?.description ? data?.description : "",
                  "image": {
                    "@type": data?.imagetype ? data?.imagetype : "",
                    "url": data?.imageurl ? data?.imageurl : "",
                    "width": data?.imagewidth ? data?.imagewidth : "",
                    "height": data?.imageheight ? data?.imageheight : "",
                  },
                  "author": {
                    "@type": data?.authortype ? data?.authortype : "",
                    "name": data?.authorname ? data?.authorname : "",
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": data?.publishername ? data?.publishername : "",
                    "logo": {
                      "@type": data?.publisherlogotype ? data?.publisherlogotype : "",
                      "url": data?.publisherlogourl ? data?.publisherlogourl : "",
                      "width": data?.publisherlogowidth ? data?.publisherlogowidth : "",
                      "height": data?.publisherlogoheight ? data?.publisherlogoheight : "",
                    }
                  },
                  "datePublished": data.datePublished,
                  "dateModified": data.dateModified
                }
            )
  },[data])

  const handleChange=(e)=>{
    console.log('==>>',e.target.name, "  ",e.target.value);
    setData({...data, [e.target.name]:e.target.value ? e.target.value :""  })
  }

  const copy=()=>{
       // Copy the text inside the text field
       var copyData = '<script type="application/ld+json">'+ JSON.stringify(predata) + "</script>"
      navigator.clipboard.writeText(copyData);
  }

  const reset=()=>{
    setData({
      "context": "https://www.rajmith.com/",   //url
    "type": "Article",
    "id":'',
    "headline": "",
    "description": "",
    "imagetype": "ImageObject",
    "imageurl": "",
    "imagewidth": "",
    "imageheight": "",
    "authortype": "Person",
    "authorname": "",
    "publishertype": "Organization",
    "publishername": "",
    "publisherlogotype": "ImageObject",
    "publisherlogourl": "",
    "publisherlogowidth": "",
    "publisherlogoheight": "",
    "datePublished": "",
    "dateModified": ""
    })
  }

  const handleValidSubmit =()=>{
    copy()
  }

  return (
    <React.Fragment>M,
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Site map Generator" />
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
              <div className="p-2">
                <p>Site Map</p>
              </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>

            {/* <code>{predata}</code> */}
            
            {/* <JSONInput
                id          = 'a_unique_id'
                placeholder = { predata }
                // colors      = { darktheme }
                locale      = { locale }
                height      = '550px'
                viewOnly    ={ true}
            /> */}

            {/* <Row>
              <Col>
                <div className="mt-4">
                  <button
                    className="btn btn-primary w-50 waves-effect waves-light"
                    type="submit"
                    onClick={()=> reset()}
                  >
                  Reset
                  </button>
                </div>
              </Col>
              <Col>
                <div className="mt-4">
                  <button
                    className="btn btn-primary w-50 waves-effect waves-light"
                    type="submit"
                    // onClick={()=> copy()}
                  >
                  Copy
                  </button>
                </div>
              </Col>
            </Row> */}
          </Col>

        </Row>
        </AvForm>
      </div>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
