import PropTypes from 'prop-types'
import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import Utility from '../../pages/Utility'

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

// Other Layout related Component

import Header from "./Header"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/Rightbar";
import Utils from '../../pages/Utility'
import { Container } from 'reactstrap'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpened: false,
      stickyClass:""
    }
  }

  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scrollto 0,0
    window.scrollTo(0, 0)

    const title = this.props.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    // document.title =
    //   currentage + " | "+  Utility.projectName

    this.props.changeLayout("horizontal")
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme)
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth)
    }
  }



  //  stickNavbar = () => {
  //     let windowHeight = window.scrollY;

  //     if (windowHeight > 500) {
  //       this.setState({stickyClass:"sticky-nav"})
  //     }else{
  //       this.setState({stickyClass:""})
  //     }
  // }

  // componentDidMount(){
  //   window.addEventListener("scroll", this.stickNavbar);
  // }




  /**
   * Opens the menu - mobile
   */
  openMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened })
  }
  render() {
    // console.log(this.state.stickyClass);
    return (

      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div id="layout-wrapper">

            <header id="page-topbar" style={{backgroundColor: this.props.user?.company?.theme ? this.props.user?.company?.theme : Utils.themeColor}}>
            <Container className='inner_header_logo' fluid>
           <header>
              <div className='logo-h'>
                  <Link to="/"><img src={require('../../assets/images/seotaggenerator.png')}  alt="seotaggenerator" /></Link>
              </div>
           </header>
        </Container>
              <Header
                theme={this.props.topbarTheme}
                isMenuOpened={this.state.isMenuOpened}
                openLeftMenuCallBack={this.openMenu}
                user={this.props?.user}
              >
              </Header>

            </header>
            <div className="main-content">
              {this.props.children}
              {/* <Footer /> */}
            </div>

          </div>

        </div>
        {this.props.showRightSidebar ? <Rightbar user={this.props.user}/> : null}
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
    user: state.Profile?.user
  }
}
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout))
