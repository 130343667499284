import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom";
import Loader from '../../components/Loader';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError, userForgetLoading } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm-dark.png";
import Utils from '../Utility';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

const ForgetPasswordPage = props => {

  const [otpSent, setOtpSent] = useState(false);
  const [reset, setReset] = useState(false);
  const [isPassShow, setIsPassShow] = useState(false);
  const [isConPassShow, setIsConPassShow] = useState(false);

  const { loading } = props;

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(event, values) {
    if (!otpSent)
      props.userForgetPassword(values, props.history);
    else
      verifyOtp(values);
  }

  useEffect(() => {
    console.log('user forgot success msg', props.forgetSuccessMsg);
    if (props.forgetSuccessMsg)
      setOtpSent(true);
  }, [props.forgetSuccessMsg]);

  const verifyOtp = (values) => {
    if (values.newpass == values.conpass) {
      props.userForgetLoading(true);
      let body = {
        email: values.email,
        otp: values.otp,
        password: values.conpass
      }
      post('/reset_password', body)
        .then((json) => {
          props.userForgetLoading(false);
          if (json?.statusCode == 200) {
            toast.success(json.message);
            props.userForgetPasswordError(null);
            props.userForgetPasswordSuccess(null);
            setOtpSent(false);
            props.history.replace("/login");
          } else {
            props.userForgetPasswordError(json.error);
            props.userForgetPasswordSuccess(null);
          }
        })
    } else
      props.userForgetPasswordError('New Password and Confirm Password did not matched!');
  }

  const resetForm = () => {
    props.userForgetPasswordError(null);
    props.userForgetPasswordSuccess(null);
    setReset(false);
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div> */}
      <Loader visible={loading} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Reset Password</h5>
                    <p className="text-white-50 mb-0">Re-Password with {Utils.projectName}</p>

                    <a href="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-5">

                  <div className="p-2">
                    {props.forgetSuccessMsg && !props.forgetError ? (
                      <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    {props.forgetError ? (
                      <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          disabled={otpSent}
                        />
                      </div>

                      {otpSent &&
                        <>
                          <div className="mb-3">
                            <AvField
                              name="otp"
                              label="OTP"
                              className="form-control"
                              placeholder="Enter OTP"
                              type="text"
                              required
                              validate={{ minLength: { value: 4 }, maxLength: { value: 4 } }}
                            />
                          </div>
                          <div className="mb-3" style={{ position: 'relative' }}>
                            <AvField
                              name="newpass"
                              label="New Password"
                              className="form-control"
                              placeholder="Enter New Password"
                              type={isPassShow ? "text" : "password"}
                              required
                              validate={{ minLength: { value: 6 } }}
                            />
                            <i className={`mdi ${isPassShow ? 'mdi-eye-off' : 'mdi-eye'}`} style={{ fontSize: 20, position: 'absolute', top: 34, right: 30, cursor: 'pointer' }}
                              onClick={() => setIsPassShow(!isPassShow)}
                            ></i>
                          </div>
                          <div className="mb-3" style={{position:'relative'}}>
                            <AvField
                              name="conpass"
                              label="Confirm Password"
                              className="form-control"
                              placeholder="Enter Confirm Password"
                              type={isConPassShow ? "text" : "password"}
                              required
                              validate={{ minLength: { value: 6 } }}
                            />
                            <i className={`mdi ${isConPassShow ? 'mdi-eye-off' : 'mdi-eye'}`} style={{ fontSize: 20, position: 'absolute', top: 34, right: 30, cursor: 'pointer' }}
                              onClick={() => setIsConPassShow(!isConPassShow)}
                            ></i>
                          </div>
                        </>
                      }
                      {!reset &&
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      }
                    </AvForm>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>Remember It ? <Link onClick={() => { resetForm(); props.history.replace("/login") }} className="fw-medium text-primary"> Sign In
                  here</Link> </p>
                <p>
                  © {new Date().getFullYear()} {Utils.projectName} Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Team Rajmith
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
  userForgetLoading: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg, loading }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError, userForgetLoading })(ForgetPasswordPage)
)
