import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Breadcrumb from '../../components/Common/Breadcrumb'
import Utils from '../Utility'
import Footer from '../../components/HorizontalLayout/Footer'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import { Helmet } from 'react-helmet'
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';


const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');

  // const [Card, setCard] = useState('');
  // const [Site, setSite] = useState('');
  // const [Description, setDescription] = useState('');
  // const [Appname, setAppname] = useState('');
  // const [Appid, setAppid] = useState('');
  // const [Iphoneid, setIphoneid] = useState('');
  // const [Ipadid, setIpadid] = useState('');
  // const [Appcountry, setAppcountry] = useState('');

  const [data, setData] = useState({
    Card: 'App',
    Site: '',
    Description: '',
    Appname: '',
    Appid: '',
    Iphoneid: '',
    Ipadid: '',
    Appcountry: '',
    Title: ''
  });


  const card = data.Card
  const site = data.Site
  const description = data.Description
  const Title = data.Title
  const appname = data.Appname
  const appid = data.Appid
  const appcountry = data.Appcountry
  const iphoneid = data.Iphoneid
  const ipadid = data.Ipadid

  const [predata, setPreData] = useState({})

  useEffect(() => {
    setPreData({

    })
  }, [data])

  const handleChange = (e) => {
    // console.log('==>>',e.target.name, "  ",e.target.value);
    setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }
  const handleValidSubmit = () => {
    CopyURL()
  }

  const CopyURL = () => {

    let cardCont = `<meta name="twitter:card" content="app${card}"> \n`

    let siteCont = `<meta name="twitter:site" content="@${site}"> \n`
    let TitleCont = `<meta name="twitter:title" content="${Title}"> \n`
    let descriptionCont = `<meta name="twitter:description" content="${description}"> \n`
    let appnameContIphone = `<meta name="twitter:app:name:iphone" content="${appname}"> \n`
    let iphoneidCont = `<meta name="twitter:app:id:iphone" content="${iphoneid}"> \n`
    let appnameContIpad = `<meta name="twitter:app:name:ipad" content="${appname}"> \n`
    let ipadidCont = `<meta name="twitter:app:id:ipad" content="${ipadid}"> \n`
    let appnameCont = `<meta name="twitter:app:name:googleplay" content="${appname}"> \n`
    let appidCont = `<meta name="twitter:app:id:googleplay" content="${appid}"> \n`
    let appcountryCont = `<meta name="twitter:app:country" content="${appcountry}">\n`

    var copyData = cardCont + siteCont + TitleCont + descriptionCont + appnameContIphone + iphoneidCont + appnameContIpad + ipadidCont + appnameCont + appidCont + appcountryCont

    navigator.clipboard.writeText(copyData);

  }

  const reset = () => {
    setData({
      Card: '',
      Site: '',
      Description: '',
      Appname: '',
      Appid: '',
      Iphoneid: '',
      Ipadid: '',
      Appcountry: ''
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title> Twitter Card Generator- SeoTagGenerator</title>
        <meta name="description" content="Boost your social media game with our Twitter Card Generator - the ultimate SEO tool for every digital marketer." />
        <meta name="keywords" content="Twitter Card Generator, Online Twitter Card Generator, Free Twitter Card Meta Tags Generator, Twitter Card Meta Tags Generator, social media card generator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/twittercard" />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT=" Twitter Card Generator, Online Twitter Card Generator, Free Twitter Card Meta Tags Generator, Twitter Card Meta Tags Generator, social media card generator" />
        <meta name="dc.description" CONTENT="Boost your social media game with our Twitter Card Generator - the ultimate SEO tool for every digital marketer." />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Twitter Card Generator- SeoTagGenerator" />
        <meta property="og:description" content="Boost your social media game with our Twitter Card Generator - the ultimate SEO tool for every digital marketer." />
        <meta property="og:url" content="https://www.seotaggenerator.com/twittercard" />
          <meta property="og:site_name" content="seotaggenerator.com" />
          <meta name="twitter:card" content="summary" />
          <meta property="twitter:title" content="Twitter Card Generator - SeoTagGenerator" />
          <meta name="twitter:image" content="" />
          <meta property="twitter:description" content="Boost your social media game with our Twitter Card Generator - the ultimate SEO tool for every digital marketer" />
          <link rel="canonical" href="https://www.seotaggenerator.com/twittercard" />

      </Helmet>
      <div className="page-content pt-5">
        {/* <Loader visible={loading} /> */}
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          {/* <h1>Twitter Card Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="Twitter Card Generator" />
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}


                    <div className="mb-3">
                      <AvField
                        name="Card"
                        label="Type"
                        className="form-control"
                        placeholder="Type"
                        type="select"
                        value={data.Card}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value={"App"}>App</option>
                        <option value={"Player"}>Players</option>
                        <option value={"Product"}>Product</option>
                        <option value={"Summary"}>Summary</option>
                        <option value={"Summary Wwith Large image"}>Summary Wwith Large image</option>
                      </AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Site"
                        label="Site Username"
                        type="text"
                        value={data.Site}
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder=""
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="Appname"
                        label="App Name"
                        value={data.Appname}
                        onChange={(e) => handleChange(e)}
                        placeholder=""
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Iphoneid"
                        label="iPhone App ID"
                        value={data.Iphoneid}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                        placeholder=""
                      />
                    </div>

                    {/* "imagetype": "", */}
                    <div className="mb-3">
                      <AvField
                        name="Ipadid"
                        label="iPad App ID"
                        className="form-control"
                        placeholder=""
                        value={data.Ipadid}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Appid"
                        label="Google Play App ID"
                        className="form-control"
                        placeholder=""
                        value={data.Appid}
                        onChange={(e) => handleChange(e)}
                        type="text"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Appcountry"
                        label="App Country (If Not Available in US Store)"
                        className="form-control"
                        placeholder=""
                        value={data.Appcountry}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Title"
                        label="Title"
                        className="form-control"
                        placeholder=""
                        value={data.Title}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="Description"
                        label="Description"
                        className="form-control"
                        placeholder=""
                        value={data.Description}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>

                  </div>
                  <Row>

                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>

              <div style={{ backgroundColor: 'black', padding: 10 }}>

                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:card"</span> content=<span style={{ color: '#CE8453' }}>"{card}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:site"</span> content=<span style={{ color: '#CE8453' }}>"@{site}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:title"</span> content=<span style={{ color: '#CE8453' }}>"{Title}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:description"</span> content=<span style={{ color: '#CE8453' }}>"{description}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:name:googleplay"</span> content=<span style={{ color: '#CE8453' }}>"{appname}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:id:googleplay"</span> content=<span style={{ color: '#CE8453' }}>"{appid}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:name:iphone" content="</span> content=<span style={{ color: '#CE8453' }}>"{appname}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:id:iphone"</span> content=<span style={{ color: '#CE8453' }}>"{iphoneid}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:name:ipad"</span> content=<span style={{ color: '#CE8453' }}>"{appname}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:id:ipad"</span> content=<span style={{ color: '#CE8453' }}>"{ipadid}"</span>{` >`}</p>
                <p style={{ color: '#9CDCFE' }}>{`<meta name=`}<span style={{ color: '#CE8453' }}>"twitter:app:country"</span> content=<span style={{ color: '#CE8453' }}>"{appcountry}"</span>{` >`}</p>

              </div>
              <Row>
                <Col>
                  <div className="mt-4 btn_section">
                    <button
                      className="btn left_btn w-50 waves-effect waves-light"
                      type="submit"
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                    <button
                      className="btn right_btn w-50 waves-effect waves-light"
                      type="submit"
                    // onClick={()=> CopyURL()}
                    >
                      Copy URL
                    </button>
                  </div>
                </Col>

              </Row>

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>SEOTagGenerator.com is an all-in-one online platform that provides a wide range of SEO tools to help businesses and website owners improve their online visibility. One of the key features of SEOTagGenerator.com is the Twitter Card Generator tool, which allows users to create Twitter cards for their website easily and quickly.</p>
            <p>Twitter cards are a type of meta tag that allow websites to display rich media content such as images, videos, and audio directly on Twitter. By creating Twitter cards, businesses and website owners can increase their website's visibility and engagement on Twitter.
            </p>
            <p>The Twitter Card Generator tool on SEOTagGenerator.com is highly customizable, allowing users to choose from a variety of card types, including summary, summary with large image, player, and app. Users can also enter details such as the title, description, and image of their website's content to ensure that their Twitter cards accurately reflect their website's information.</p>
            <p>In addition to being easy to use and highly customizable, the Twitter Card Generator tool on SEOTagGenerator.com is also free, making it accessible to businesses and website owners of all sizes. With this powerful tool at their disposal, users can increase their website's visibility and engagement on Twitter, drive more traffic to their website, and ultimately, achieve their business goals.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>SEOTagGenerator.com's Twitter Card Generator tool provides numerous benefits for businesses and website owners looking to increase their website's visibility and engagement on Twitter. Here are some of the key benefits of using this tool:
            </p>
            <ul>
              <li>• Enhances engagement on Twitter: By creating visually appealing Twitter cards with rich media content such as images, videos, and audio, businesses and website owners can increase their website's engagement on Twitter.
              </li>
              <li>• Improves website's visibility: Twitter cards can also improve a website's visibility on Twitter by providing users with more information about a website's content, making it more likely that users will click through to the website.
              </li>
              <li>• Easy to use: The Twitter Card Generator tool on SEOTagGenerator.com is easy to use, even for those with little coding experience. Users simply input information about their website's content, and the tool generates the necessary code for them.
              </li>
              <li>• Highly customizable: The tool allows users to choose from a variety of card types and customize the code to their specific needs. This means that businesses can create Twitter cards that accurately reflect their website's content and ensure that they stand out on Twitter.
              </li>

              <p className='mt-3'>
                Overall, SEOTagGenerator.com's Twitter Card Generator tool is a powerful tool for businesses and website owners looking to improve their website's visibility and engagement on Twitter. By utilizing this tool, users can enhance their website's visibility, drive more traffic to their website, and ultimately achieve their business goals.

              </p>
            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q.What is a Twitter card, and why should I use it?</h6>
            <p>A. Twitter card is a type of meta tag that allows users to display rich media content such as images, videos, and audio directly on Twitter. Using Twitter cards can improve engagement and click-through rates, making it more likely that users will click through to your website.
            </p>
            <h6>Q.Is the Twitter Card Generator tool on SEOTagGenerator.com free to use?
            </h6>
            <p>A.Yes, the Twitter Card Generator tool is completely free to use.
            </p>
            <h6>Q.How do I use the Twitter Card Generator tool?
            </h6>
            <p>A. Using the tool is easy. Simply enter the relevant information about your website's content, and the tool generates the code necessary to create your Twitter card.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?
            </h5>
            <p>Using the Twitter Card Generator on SEOTagGenerator.com can be a valuable tool for businesses and website owners looking to increase their Twitter presence. By creating visually appealing Twitter cards with rich media content, users can improve engagement and click-through rates, making it more likely that users will click through to their website. Additionally, the tool is free, easy to use, and highly customizable, making it accessible to businesses and website owners of all sizes. Overall, the Twitter Card Generator on SEOTagGenerator.com is a powerful tool that can help businesses achieve their marketing goals and grow their business.</p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
