import PropTypes from 'prop-types'
import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
import Utils from '../Utility'
import Breadcrumb from '../../components/Common/Breadcrumb'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';


const Leads = props => {
  const { user } = props;
  const [strongPass, setStrongPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [chara, setChara] = useState('');
  const [statusBtn, setStatusBtn] = useState(false);
  // const []

  const [data, setData] = useState({
    passlength: '8',
    cap: true,
    small: true,
    number: true,
    symbol: true,
    // CampaignContents:''
  });

  const [predata, setPreData] = useState({})


  const handleChange = (e) => {
    console.log('==>>', e.target.name, "  ", e.target.value);
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const buildurl = () => {
    let numVal = "0123456789"
    let smallVal = "abcdefghijklmnopqrstuvwxyz"
    let capitalVal = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let symbolVal = "!@#$%^&*()"
    let password = "";
    let temp = "";

    for (var i = 0; i < Number(data.passlength); i++) {
      if (data.number) {
        temp = temp + numVal.charAt(Math.floor(Math.random() * numVal.length));
      }
      if (data.cap) {
        temp = temp + capitalVal.charAt(Math.floor(Math.random() * capitalVal.length));
      }
      if (data.small) {
        temp = temp + smallVal.charAt(Math.floor(Math.random() * smallVal.length));
      }
      if (data.symbol) {
        temp = temp + symbolVal.charAt(Math.floor(Math.random() * symbolVal.length));
      }
    }
    for (var i = 0; i < Number(data.passlength); i++) {
      password = password + temp[i];
    }
    console.log(' printing password with random ==>', password)

    setStrongPass(password)
  }

  const handleCase = (e) => {
    console.log('allday==>', e.target.value, e.target.name, chara)
    setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }

  const reset = () => {
    setData({
      passlength: '',
      cap: '',
      small: '',
      number: '',
      symbol: '',
    })
    setStrongPass('')
  }

  const handleValidSubmit = () => {
    buildurl()
  }

  const copyurlFunction = () => {
    setStatusBtn(true);
    navigator.clipboard.writeText(strongPass);
    setTimeout(() => {
      setStatusBtn(false);
    }, 400);

  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Strong Password Generator- SeoTagGenerator</title>
        <meta name="description" content="Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information" />
        <meta name="keywords" content="Twitter Card Generator, Online Twitter Card Generator, Free Twitter Card Meta Tags Generator, Twitter Card Meta Tags Generator, social media card generator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/passgenerator" />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT=" Twitter Card Generator, Online Twitter Card Generator, Free Twitter Card Meta Tags Generator, Twitter Card Meta Tags Generator, social media card generator" />
        <meta name="dc.description" CONTENT="Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information." />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Strong Password Generator- SeoTagGenerator" />
        <meta property="og:description" content=" Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information." />
        <meta property="og:url" content="https://www.seotaggenerator.com/passgenerator" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="Strong Password Generator- SeoTagGenerator" />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information." />
        <link rel="canonical" href="https://www.seotaggenerator.com/passgenerator" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >

          {/* <h1>Strong Password Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="Strong Password Generator" />
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}


                    <Row>
                      <Col>
                        <p>Password Length ( e.g. minimum 5 and maximum 20 )</p>

                      </Col>
                      <Col>
                        <div className="mb-3 w-50">
                          <AvField
                            name="passlength"
                            label=""
                            type="number"
                            value={data.passlength}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder=""
                            max="50"
                            min="5"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Include Uppercase Letters ( e.g. ABCDEFGH )</p>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvGroup check>
                            <AvInput type="checkbox" name="cap" onChange={(e) => setData({ ...data, cap: !data.cap })} value={data.cap} />
                          </AvGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Include Lowercase Letters ( e.g. abcdefgh )</p>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvGroup check>
                            <AvInput type="checkbox" name="small" onChange={(e) => setData({ ...data, small: !data.small })} value={data.small} />
                          </AvGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Include Numbers ( e.g. 123456 )</p>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvGroup check>
                            <AvInput type="checkbox" name="number" onChange={(e) => setData({ ...data, number: !data.number })} value={data.number} />
                          </AvGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Include Symbols ( e.g. @#$% )</p>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvGroup check>
                            <AvInput type="checkbox" name="symbol" onChange={(e) => setData({ ...data, symbol: !data.symbol })} value={data.symbol} />
                          </AvGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col>
                      <div className="mt-4 btn_section">
                        <button
                          className="btn left_btn w-50 waves-effect waves-light"
                          type="submit"
                          onClick={() => reset()}
                        >
                          Reset
                        </button>
                        <button
                          className="btn right_btn w-50 waves-effect waves-light"
                          type="submit"
                        // onClick={() => buildurl()}
                        >
                          Build URL
                        </button>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <div style={{ backgroundColor: 'black', padding: 10, position: 'relative' }}>
                <Row>
                  <Col>
                    <p style={{ color: '#9CDCFE' }}>{`Here is Your Strong Password     `}<span style={{ color: '#CE8453' }}>"{strongPass}"</span></p>
                  </Col>
                  <button className='copy_icon_btn' onClick={copyurlFunction}>
                    {statusBtn == false ? <i className="dripicons-copy" /> : <i className="mdi mdi-check" />}
                  </button>
                </Row>
              </div>
            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>The Password Generator on seotaggenerator.com is a powerful tool that can help you create strong and secure passwords for your online accounts. Using weak passwords can leave your accounts vulnerable to hacking and other cyber threats, but creating strong passwords can be challenging and time-consuming. With the Strong Password Generator, you can quickly and easily create unique and complex passwords that are difficult for hackers to crack. Our tool generates passwords using a combination of letters, numbers, and special characters, making them virtually impossible to guess or brute-force. Using the Strong Password Generator can help you enhance your online security and protect your sensitive information from cybercriminals.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>Benefits of using the Password Generator on seotaggenerator.com:
            </p>
            <ul>
              <li>• Strong and Secure Passwords: Our tool generates strong and secure passwords that are difficult to guess or crack, providing your online accounts with an extra layer of protection.
              </li>
              <li>• Customizable Options: You can customize your password length and choose from a variety of character sets, including uppercase and lowercase letters, numbers, and symbols.
              </li>
              <li>• Time-Saving: With our Strong Password Generator, you can quickly generate a strong and secure password within seconds, saving you time and effort.
              </li>
              <li>• Convenient: You can access our Strong Password Generator from anywhere, anytime, as long as you have an internet connection.
              </li>
              <li>• Free of Charge: Our Strong Password Generator is completely free to use, and you can generate as many passwords as you need.
              </li>

            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What is a Strong Password Generator?
            </h6>
            <p>A Strong Password Generator is an online tool that creates complex and random passwords for users to ensure their online accounts' security. It helps generate a password that is difficult to guess or crack by combining random letters, numbers, and special characters.
            </p>
            <h6>Q. Is it safe to use the Strong Password Generator on seotaggenerator.com?
            </h6>
            <p>Yes, it is safe to use the Strong Password Generator on seotaggenerator.com. Our tool generates passwords locally in your browser, which means that your password does not travel over the internet, making it difficult for cybercriminals to intercept it.
            </p>
            <h6>Q.How do I use the Strong Password Generator on seotaggenerator.com?
            </h6>
            <p>Using the Strong Password Generator is easy. Simply visit seotaggenerator.com and navigate to the Strong Password Generator tool. Choose your preferred password length and complexity, and the tool will generate a strong and secure password for you. You can then copy and use the password for your online accounts.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>You should use the Password Generator on seotaggenerator.com to ensure that your online accounts are secured with strong and unique passwords. With the rise of cyber threats, it's more important than ever to have strong passwords that are difficult for hackers to guess. Our Password Generator can create random, complex passwords that are virtually impossible to crack. Additionally, our tool allows you to customize the length and complexity of the password, so you can generate passwords that meet your specific security needs. By using our Password Generator, you can have peace of mind knowing that your online accounts are well-protected.

            </p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
