import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Container
} from "reactstrap"

// availity-reactstrap-validation
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
import JsonViewer from '../../components/jsonViewer'
import Breadcrumb from '../../components/Common/Breadcrumb'
import Utils from '../Utility'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';


const Leads = (props) => {
  const { user } = props;
  const [showDays, setShowDays] = useState(true);
  const [showWeek, setShowWeek] = useState(true);
  const [loading, setLoading] = useState(false);
  const [OpenHour, setOpenHour] = useState(false);
  const editorRef = useRef();

  const weekArr = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const [data, setData] = useState({
    "name": "",
    "imageurl": "",
    "idurl": '',
    "url": "",
    "phone": "",
    "pricerange": "",
    "street": "",
    "city": "",
    "zipCode": "",
    "state": "",
    "country": "",
    "open": false,


    "context": "https://schema.org",   //url
    "type": "LocalBusiness",
  });

  const [preData, setpreData] = useState(
    {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "",
      "image": "",
      "@id": "",
      "url": "",
      "telephone": "",
      "address": {
        "@type": "",
        "streetAddress": "",
        "addressLocality": "",
        "postalCode": "",
        "addressCountry": "",
        "addressRegion": ""
      },
      "priceRange": "",
    }
  )

  useEffect(() => {
    let temp = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",

      "name": data?.name ? data?.name : "",
      "image": data?.imageurl ? data?.imageurl : "",
      "@id": data?.idurl ? data?.idurl : "",
      "url": data?.url ? data?.url : "",
      "telephone": data?.phone ? data?.phone : "",
      "price": data?.price ? data?.price : "",

      "address": {
        "@type": "PostalAddress",
        "streetAddress": data?.street ? data?.street : "",
        "addressLocality": data?.city ? data?.city : "",
        "postalCode": data?.zipCode ? data?.zipCode : "",
        "addressCountry": data?.country ? data?.country : "",
        "addressRegion": data?.state ? data?.state : "",
      },
      "priceRange": "",
      "openingHoursSpecification": data?.openingHoursSpecification ? data?.openingHoursSpecification : undefined
    }
    setpreData(temp);
    // console.log('pre data after effected', temp);
  }, [data]);

  useEffect(() => {
    // editorRef.current. = true;
    //  = {'ritik':'saini'};
  }, [preData]);

  const allday = (e) => {
    console.log(e.target.value, typeof (e.target.value), e.target.name)
    var val = e.target.value
    if (e.target.value != "true") {
      console.log('working with check box');
      setShowDays(false)
      var obj = {
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "00:00",
          "closes": "23:59"
        }
      }
      let temp = Object.assign({}, data);
      temp = { ...temp, ...obj };
      setData(temp);
      // console.log(e.target.value, " ===>>> fill obj", temp)
    }
    else {
      let temp = Object.assign({}, data);
      delete temp.openingHoursSpecification;
      temp = { ...temp };
      setData(temp);
      setShowDays(true)
      // console.log(e.target.value, " ===>>> fill obj", temp)
      // console.log(e.target.value, " ===>>> empty obj")
    }

  }

  const addOpenHour = () => {

    setOpenHour(true)
    setShowWeek(false)
    var obj = {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      ],
      "opens": "",
      "closes": ""
    }
    let temp = Object.assign({}, data);
    let arr = temp.openingHoursSpecification || [];
    arr.push(obj);
    // console.log(" RR===>>> fill obj", arr)
    // setData({ ...temp, openingHoursSpecification: arr, });
    // setTimeout(() => {
    let preName = temp.name
    temp = { ...temp, name: new Date(), openingHoursSpecification: arr }
    setData(temp);
    setTimeout(() => {
      setData({ ...temp, name: preName });
    }, 200);
    // }, 200);


  }

  const handleChange = (e) => {
    // console.log('==>>', e.target.name, "  ", e.target.value);
    if (e.target.name == "phone") {
      var value = e.target.value.split(" ").join("")
      setData({ ...data, [e.target.name]: value ? value.length < 2 ? "0" + value : value : "" })
    } else
      setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }

  const handleValidSubmit = () => {
    CopyURL()
  }

  const CopyURL = () => {
    // Copy the text inside the text field
    var copyData = '<script type="application/ld+json">' + JSON.stringify(preData) + "</script>"
    navigator.clipboard.writeText(copyData);
  }

  const reset = () => {
    setData({
      "context": "https://schema.org",   //url
      "type": "Article",
      "name": "",
      "imageurl": "",
      "idurl": '',
      "url": "",
      "phone": "",
      "pricerange": "",
      "street": "",
      "city": "",
      "zipcode": "",
      "state": "",
      "country": "",
      "open": false,
    })
  }
  const setDays = (e, index, index2, item) => {
    let name = e.target.name
    let value = e.target.value

    if (value == "false") {
      let temp = Object.assign({}, preData);
      let arr = temp?.openingHoursSpecification
      // let temp = Object.assign([], ImageArray)
      arr[index].dayOfWeek.push(name)
      let preName = temp.name
      temp = { ...temp, name: new Date(), }
      setData(temp);
      setTimeout(() => {
        setData({ ...temp, name: preName });
      }, 200);
    } else {
      let temp = Object.assign({}, preData);

      temp.openingHoursSpecification[index].dayOfWeek?.map((days, indexx) => {
        if (days == item) {
          temp.openingHoursSpecification[index].dayOfWeek.splice(indexx, 1)

          let preName = temp.name
          temp = { ...temp, name: new Date(), }
          setData(temp);
          setTimeout(() => {
            setData({ ...temp, name: preName });
          }, 200);
        }
      })
    }

  }
  const deleteWeek = (item, index) => {
    let temp = Object.assign({}, preData);
    temp.openingHoursSpecification.splice(index, 1)

    let preName = temp.name
    temp = { ...temp, name: new Date(), }
    setData(temp);
    setTimeout(() => {
      setData({ ...temp, name: preName });
    }, 200);

    if (temp?.openingHoursSpecification?.length < 1) {
      setShowWeek(true)
      setOpenHour(false)
      setTimeout(() => {
        let temp = Object.assign({}, data);
        temp.openingHoursSpecification = undefined;
        temp = { ...temp };
        setData(temp);

      }, 200);

    }
  }
  const updateTime = (e, index, item) => {
    let name = e.target.name
    let value = e.target.value

    let temp = Object.assign({}, preData);
    let arr = temp?.openingHoursSpecification
    // let temp = Object.assign([], ImageArray)
    // arr[index].name = value
    if (name == "opens") {
      arr[index].opens = value
    } else {
      arr[index].closes = value
    }
    // closes: ''
    let preName = temp.name
    temp = { ...temp, name: '- ', }
    setData(temp);
    setTimeout(() => {
      setData({ ...temp, name: preName });
    }, 200);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>hCard generator - SeoTagGenerator </title>
        <meta name="description" content="Elevate your local business's online presence with the free hCard generator tool! Effortlessly generate microformat hCard with structured data markup to improve your website's SEO. Get started today for free and see the results for yourself! | hCard generator" />
        <meta name="keywords" content="hCard generator, hcard in html, local business schema generator, hCard Creator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/hcard" />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT="hCard generator, hcard in html, local business schema generator, hCard Creator" />
        <meta name="dc.description" CONTENT="Elevate your local business's online presence with the free hCard generator tool! Effortlessly generate microformat hCard with structured data markup to improve your website's SEO. Get started today for free and see the results for yourself! | hCard generator" />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content="SeoTagGenerator" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="hCard generator, hcard in html, local business schema generator, hCard Creator" />
        <meta property="og:description" content="Elevate your local business's online presence with the free hCard generator tool! Effortlessly generate microformat hCard with structured data markup to improve your website's SEO. Get started today for free and see the results for yourself! | hCard generator" />
        <meta property="og:url" content="https://www.seotaggenerator.com/hcard" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="hCard generator - SeoTagGenerator " />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Elevate your local business's online presence with the free hCard generator tool! Effortlessly generate microformat hCard with structured data markup to improve your website's SEO. Get started today for free and see the results for yourself! | hCard generator" />
        <link rel="canonical" href="https://www.seotaggenerator.com/hcard" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          {/* <h1 className='mb-0'>HCard Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="HCard Generator" />
          <h4>This online tool is designed for the JSON-LD format</h4>
          <p className='pb-2'>Structured Data describes the content of the webpages and what the page is all about. Search engines like Google read and understand markup languages better than human languages. Creating a Local Business Schema is the easiest and best way to let Google know that you are providing services within a city or region. There are two main types of schema that you can use based on your website architecture, JSON-LD & Microdata. Google recommends using JSON-LD format since it can read JSON-LD when it is dynamically injected into the page's contents, such as by JavaScript code or embedded widgets in your content management system. Use our free local business schema generator tool to create JSON-LD schema markup for your business. We will help you generate Schema.org structured data markup to display your business with a rich snippet format on the Google search engine result page. It will let you syndicate NAP ( Name, Address, Phone Number ) details. When the code is ready, you can put that into your website. We recommend using the Google Structured Data Testing Tool to test your code once it is finalized.</p>

          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}

                    <div className="mb-3">
                      <AvField
                        name="name"
                        label="Name"
                        value={data.name}
                        onChange={(e) => handleChange(e)}
                        placeholder="Name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="imageurl"
                        label="Image URL"
                        className="form-control"
                        placeholder="Image URL"
                        value={data.imageurl}
                        onChange={(e) => handleChange(e)}
                        type="url"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="idurl"
                        label="ID URL"
                        className="form-control"
                        placeholder="@ID URL"
                        value={data.idurl}
                        onChange={(e) => handleChange(e)}
                        type="url"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="url"
                        label="URL"
                        className="form-control"
                        placeholder="URL"
                        value={data.url}
                        onChange={(e) => handleChange(e)}
                        type="url"
                        required
                      />
                    </div>
                    {/* <div className="mb-3">
                      <AvField
                        name="Categories"
                        label="Categories (comma separated)"
                        className="form-control"
                        placeholder=""
                        value={data.Categories}
                        onChange={(e) => handleChange(e)}
                        type="url"
                        required
                      />
                    </div> */}

                    <div className="mb-3">
                      <AvField
                        name="phone"
                        label="Phone"
                        value={data.phone}
                        onChange={(e) => handleChange(e)}
                        placeholder="Phone Number"
                        // type="tel"
                        type="text" validate={{ number: true }}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="price"
                        label="Price Range"
                        value={data.price}
                        onChange={(e) => handleChange(e)}
                        type="number"
                        required
                        placeholder="Price Range"
                      />
                    </div>

                    {/* "imagetype": "", */}
                    <div className="mb-3">
                      <AvField
                        name="street"
                        label="Street"
                        className="form-control"
                        placeholder="Street"
                        value={data.street}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="city"
                        label="City"
                        className="form-control"
                        placeholder="City"
                        value={data.city}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="zipCode"
                        label="Zip Code"
                        className="form-control"
                        placeholder="Zip Code"
                        value={data.zipCode}
                        onChange={(e) => handleChange(e)}
                        type="number"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="state"
                        label="State"
                        className="form-control"
                        placeholder="State"
                        value={data.state}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="country"
                        label="Country"
                        className="form-control"
                        placeholder="Country"
                        value={data.country}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    {/* Week here */}
                    {OpenHour &&
                      <div>
                        {preData?.openingHoursSpecification?.map((item, index) => {
                          return (
                            <div className="mt-5 mb-5">
                              <Row>
                                <button
                                  className="btn left_btn close_btn_new mt-10 mb-5"
                                  onClick={(e) => deleteWeek(item, index)}
                                  style={{
                                    position: 'absolute',
                                    width: '30px',
                                    height: '30px',
                                    right: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 1,
                                  }}
                                >
                                  <i className='mdi mdi-close' />
                                </button>
                                {weekArr.map((item, index2) => {
                                  return (
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <AvGroup check>
                                          <AvInput type="checkbox" name={item} onChange={(e) => setDays(e, index, index2, item)} value={"Monday"} /> {item ? item : undefined}
                                        </AvGroup>
                                      </div>
                                    </Col>
                                  )
                                })}

                              </Row>
                              <Row>
                                <Col md={6}>
                                  <AvField
                                    name="opens"
                                    label="Opens At"
                                    className="form-control"
                                    placeholder=""
                                    value={item.opens}
                                    onChange={(e) => updateTime(e, index)}
                                    type="time"
                                  />
                                </Col>
                                <Col md={6}>
                                  <AvField
                                    name="closes"
                                    label="Close At"
                                    className="form-control"
                                    placeholder=""
                                    value={item.closes}
                                    onChange={(e) => updateTime(e, index)}
                                    type="time"
                                  />
                                </Col>

                              </Row>
                            </div>
                          )
                        })}
                      </div>
                    }
                    <Row>
                      {showWeek ?
                        <Col md={12}>
                          <div className="mb-3">
                            <AvGroup check>
                              <AvInput type="checkbox" name="open" onChange={(e) => allday(e)} value={data.open} /> Open 24/7
                            </AvGroup>
                          </div>
                        </Col>
                        : null}
                      {showDays ?
                        <Col md={12}>
                          <div className="btn left_btn mb-3 mt-20" onClick={(e) => addOpenHour(e)} style={{ backgroundColor: "#3b5de" }} >
                            <span>+ Add opening Hours</span>
                          </div>
                        </Col>
                        : null}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} className="JsonViewer_css_react">

              {/* <code>{preData}</code> */}

              <JsonViewer style={{ width: '100%' }} data={preData} />

              {/* {JsonViewer(preData)} */}
              <div className='mt-2' >
                <Row>
                  <Col>
                    <div className="mt-4 btn_section">
                      <button
                        className="btn left_btn w-50 waves-effect waves-light"
                        type="submit"
                        onClick={() => reset()}
                      >
                        Reset
                      </button>
                      <button
                        className="btn right_btn w-50 waves-effect waves-light"
                        type="submit"
                        onClick={() => CopyURL()}
                      >
                        Copy
                      </button>
                    </div>
                  </Col>

                </Row>
              </div>
            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>SEOTagGenerator.com's HCard Generator tool is designed to simplify the process of creating hCard microformats for your website. An hCard is a microformat that provides a standardized way to add contact information to your website, making it easier for search engines to understand and display your information in search results. By using our free and user-friendly tool, you can easily create hCards with all the necessary information, including name, address, phone number, email, and more. This can help improve the visibility and accessibility of your contact information online, leading to more engagement and potentially increased business opportunities. With SEOTagGenerator.com's HCard Generator tool, you can take control of your website's contact information and optimize it for search engines, making it easier for potential customers to find and connect with you.

            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>Using SEOTagGenerator.com's HCard Generator tool provides a multitude of benefits for website owners who want to enhance their online presence.
              Firstly, hCards help to provide structured data to search engines, which can improve the visibility of your contact information in search results. This can lead to increased engagement, more potential customers, and higher conversion rates. Secondly, hCards provide a standardized way of formatting contact information, making it easier for users to find and understand your contact details. This can help build trust with potential customers and enhance the user experience on your website. Finally, the HCard Generator tool is free and user-friendly, making it easy for website owners of all technical abilities to create hCards quickly and accurately. By using SEOTagGenerator.com's HCard Generator, you can optimize your contact information for search engines and improve your online visibility and engagement.
            </p>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What is an hCard and why is it important for my website?
            </h6>
            <p>A. An hCard is a type of microformat that provides structured data about contact information, making it easier for search engines to identify and display your contact details in search results. This can improve your website's visibility and attract more potential customers.
            </p>
            <h6>Q. Is the SEOTagGenerator.com HCard Generator tool free to use?
            </h6>
            <p>A. Yes, the HCard Generator tool is completely free to use and requires no registration or download.
            </p>
            <h6>Q. Can I customize the appearance of my hCard using the SEOTagGenerator.com tool?
            </h6>
            <p>A. No, the HCard Generator tool generates the hCard in accordance with the standard formatting guidelines. However, you can copy and paste the generated code into your website's HTML and apply your own styling using CSS.
            </p>
            <h6>Q. How do I add the hCard to my website once it's generated?
            </h6>
            <p>A. Once you have generated your hCard using the SEOTagGenerator.com tool, simply copy and paste the generated code into the relevant section of your website's HTML. Be sure to test the hCard to ensure that it is properly formatted and displaying the correct information.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>By using the hCard Generator tool on SEOTagGenerator.com, you can improve the visibility and credibility of your website. The tool provides a simple and efficient way to create hCards that contain structured data about your contact information, which can help search engines understand and display your business details in a more user-friendly manner.
            </p>
            <p>Using hCards can also increase the chances of attracting potential customers and clients to your website. By providing accurate and easily accessible contact information, you can establish trust and credibility with your audience, and encourage them to reach out to you for further information or services.
            </p>
            <p>Furthermore, the hCard Generator on SEOTagGenerator.com is completely free and easy to use, requiring no technical expertise or coding knowledge. So, take advantage of this powerful tool today and give your website the boost it deserves!

            </p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
