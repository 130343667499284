import React from "react"
import { Redirect } from "react-router-dom"


//pages Schema
import Schema from "../pages/Schema";
import Twittercard from "../pages/Twittercard";

import HCard from "../pages/HCard";
import UTMurl from "../pages/UTMurl";
import Ogtag from "../pages/Ogtag";
import Breadcrumbs from "../pages/Breadcrumbs";
import Robotstxt from "../pages/Robotstxt";
import Passgenerator from "../pages/Passgenerator";
import hreflang from "../pages/hreflang";
import metatag from "../pages/metatag";
import sitemap from '../pages/Sitemap'







// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

import Home from '../pages/Home'
import PrivacyPolicy from '../pages/Policy/Policy'
import CookiePolicy from '../pages/CookiePolicy'

const userRoutes = [


  { path: "/utmurl", component: UTMurl },
  { path: "/schema", component: Schema },
  { path: "/twittercard", component: Twittercard },
  { path: "/ogtag", component: Ogtag },
  { path: "/breadcrumbs", component: Breadcrumbs},
  { path: "/robotstxt", component: Robotstxt},
  { path: "/passgenerator", component: Passgenerator},
  { path: "/hcard", component: HCard },
  { path: "/hreflang", component: hreflang },
  { path: "/metatag", component: metatag },
  { path: "/sitemap", component: sitemap },



  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/" /> },


]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },



  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
  // {path: '/', component:Home},


]

const homeRout = [
   {path: '/', component:Home},
   {path: '/privacy-policy', component:PrivacyPolicy},
   {path: '/cookie-policy', component:CookiePolicy},]


export { userRoutes, authRoutes, homeRout }