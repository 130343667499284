import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom";
import Utils from '../../pages/Utility';
import { get } from "../../helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import { getNotiSuccess } from "../../store/actions";

const SidebarContent = props => {
  // console.log('props on slidebar content', props);
  const [subMenu, setSubMenu] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [isDealer, setIsDealer] = useState(false);
  const [isAutoLead, setIsLeadAuto] = useState(false);


  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    item.style.color = Utils.themeColor;
    item.style.fontWeight = 'bold';  
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-airplay" style={{ color: Utils.themeColor }}></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {/* {props.user?.role == 'Admin' && */}
              <>
                <li>
                  <Link to="/schema" className="waves-effect">
                    <i className="mdi mdi-account-circle-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Generate Schema")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/HCard" className="waves-effect">
                    <i className="mdi mdi-calendar-check" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("H Card")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/users" className="waves-effect">
                    <i className="mdi mdi-account-circle-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Patients")}</span>
                  </Link>
                </li>
              </>
            {/* } */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  getNotiSuccess: PropTypes.func,
}

export default withRouter(withTranslation()(connect(mapStateToProps, { getNotiSuccess })(SidebarContent)))