import PropTypes from 'prop-types'
import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
import Utils from '../Utility'
import Breadcrumb from '../../components/Common/Breadcrumb'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';


const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');
  const [loading, setLoading] = useState(false);
  // const []

  const [data, setData] = useState({
    websiteurl: '',
    allowallRobo: 'Allow',
    allowRuleVal: 'Allow',
    sitemapurl: '',
    filepath: '',
    robot: 'All',
    RuleArr: []
  });

  const [predata, setPreData] = useState({})

  const handleChange = (e) => {
    console.log('==>>', e.target.name, " <==  ");
    setData({ ...data, [e.target.name]: e.target.value ? e.target.value.trimStart() : "" })
  }

  const buildurl = () => {
    // Copy the text inside the text field
    //  var copyData = '<script type="application/ld+json">'+ JSON.stringify(predata) + "</script>"
    // var copyData = data.websiteurl+data.CampaignSource+data.CampaignMedium+data.CampaignName+data.CampaignTerms+data.CampaignContents
    // navigator.clipboard.writeText(copyData);
    var url = data.websiteurl
    if (url) {
      var url = data.websiteurl
      var Source = data.CampaignSource
      var medium = data.CampaignMedium
      var name = data.CampaignName
      var term = data.CampaignTerms
      var content = data.CampaignContents

      // var Nurl = ''
      var NSource = ''
      var Nmedium = ''
      var Nname = ''
      var Nterm = ''
      var Ncontent = ''

      url = url + "?"
      if (Source.length) {
        NSource = "utm_source=" + Source + "&"
      } else { NSource = Source }
      if (medium.length) {
        Nmedium = "utm_medium=" + medium + "&"
      } else { Nmedium = medium }
      if (name.length) {
        Nname = "utm_campaign=" + name + "&"
      } else { Nname = name }
      if (term.length) {
        Nterm = "utm_term=" + term + "&"
      } else { Nterm = term }
      if (content.length) {
        Ncontent = "utm_content=" + content + "&"
      } else { Ncontent = content }


      var URL = url + NSource + Nmedium + Nname + Nterm + Ncontent

      var length = URL.length
      if (URL[length - 1] == "&") {
        URL = URL.slice(0, -1)
      }
      URL = URL.replace(/ /g, "%20").replace(/@/g, "%40").replace(/&/g, "%26").replace(/-/g, "%2D").replace(/;/g, "%3B").replace(/_/g, "%5F")
      console.log('==>', URL,)
      setCompleteurl(URL)

    }

  }

  const allowallRob = (e) => {
    setData({ ...data, allowallRobo: e.target.value, })
  }

  const allowRule = (e) => {
    setData({ ...data, allowRuleVal: e.target.value })
  }

  const AddRule = () => {
    if (data.filepath[0] == "/") {
      var arr = data.RuleArr
      let temp = {}
      temp.allowRuleVal = data.allowRuleVal
      temp.robot = data.robot
      temp.filepath = data.filepath
      // temp[index].action = data.allowRobo
      data.RuleArr.push(temp)
      console.log('======= ', temp, "  ", JSON.stringify(data.RuleArr))
      setData({ ...data, RuleArr: arr, allowRuleVal: 'Allow', robot: 'All', filepath: '', })
    }
  }

  const deleteAction = (index) => {
    // console.log(index," = ", data.RuleArr.length , ",    data before delete=>", data.RuleArr)
    let temp = Object.assign({}, data);
    temp.RuleArr.splice(index, 1)
    console.log("data =>", temp.RuleArr)
    setData(temp);
  }

  const updateArr = (e, index) => {
    var name = e.target.name
    var value = e.target.value

    let temp = Object.assign([], data.RuleArr)
    console.log(name, ' <=> ', `itemAction${index}`, value, " <=>", temp)
    if (name == "filepath") {
      temp[index].filepath = value

    } if (name == "robot") {
      temp[index].robot = value
    }
    if (name == `itemAction${index}`) {
      temp[index].allowRuleVal = value
    }
    setData({ ...data, RuleArr: temp })
  }

  const creatText = () => {
    let userAgent = ` \n User-Agent: * \n`
    let disAllow = ` Disallow: ${data.allowallRobo == "Disallow" ? "/" : ''} \n`
    let allRobo = ''
    let robots = ''
    data.RuleArr?.map((item, index) => {
      if (item?.robot == "All") {
        allRobo = allRobo + `\n\n ${item?.allowRuleVal} : ${item?.filepath}`
      }
    })
    data.RuleArr?.map((item, index) => {
      if (item?.robot !== "All") {
        robots = robots + `\n\n\n User-Agent: ${item?.robot} \n ${item?.allowRuleVal}: ${item?.filepath}`
      }
    })
    let sitemap = `\n\n Sitemap: ${data.websiteurl}`

    console.log("Comp File ==>", userAgent + disAllow + allRobo + robots + sitemap);
    return userAgent + disAllow + allRobo + robots + sitemap;
  }
  const handleValidSubmit = () => {
    downloadTxtFile()
  }

  const downloadTxtFile = () => {
    let text = creatText();
    if (data.RuleArr?.length) {
      console.log("file val ==>", document.getElementById('MyFile'))
      const element = document.createElement("a");
      const file = new Blob([text], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = "Robottxt.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Robots.txt Generator | Generate robots.txt file instantly - SeoTagGenerator </title>
        <meta name="description" content="Optimize website crawling with ease using SEOTagGenerator's Easy to use robots.txt file generator." />
        <meta name="keywords" content="Free Robots.txt Generator, robots.txt generator, Generate robots.txt file instantly, Create a robots.txt file instantly, Robots.txt File Generator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT=" https://www.seotaggenerator.com/robotstxt " />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT="Free Robots.txt Generator, robots.txt generator, Generate robots.txt file instantly, Create a robots.txt file instantly, Robots.txt File Generator" />
        <meta name="dc.description" CONTENT="Optimize website crawling with ease using SEOTagGenerator's Easy to use robots.txt file generator." />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Robots.txt Generator | Generate robots.txt file instantly - SeoTagGenerator" />
        <meta property="og:description" content="Optimize website crawling with ease using SEOTagGenerator's Easy to use robots.txt file generator." />
        <meta property="og:url" content="https://www.seotaggenerator.com/robotstxt" />
          <meta property="og:site_name" content="seotaggenerator.com" />
          <meta name="twitter:card" content="summary" />
          <meta property="twitter:title" content="Robots.txt Generator | Generate robots.txt file instantly - SeoTagGenerator " />
          <meta name="twitter:image" content="" />
          <meta property="twitter:description" content="Optimize website crawling with ease using SEOTagGenerator's Easy-to-use robots.txt file generator." />

          <link rel="canonical" href="https://www.seotaggenerator.com/robotstxt" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          {/* <h1>Robots.txt File Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="Robots.txt File Generator" />
          <Row>
            <Col md={8}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}

                    <h4>Default robot access</h4>

                    <div className="mb-3">
                      <AvRadioGroup onChange={(e) => allowallRob(e)} value={data.allowallRobo} inline name="allowallRobo" label="" required>
                        <AvRadio customInput label="Disallow all robots" value="Disallow" />
                        <AvRadio customInput label="Allow all robots" value="Allow" />
                      </AvRadioGroup>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="websiteurl"
                        label="Sitemap (optional)"
                        className="form-control"
                        placeholder="Sitemap URL"
                        value={data.websiteurl}
                        onChange={(e) => handleChange(e)}
                        type="url"
                        validate={{
                          pattern: { value: '[\w\d\.\-\?\#_/:&amp;]+[.aspx|\/]$', errorMessage: 'You should enter full url to your sitemap' },
                        }}
                      />
                    </div>
                    <h4>Additional rules</h4>
                    <div>
                      <div className="mb-3">
                        <AvField
                          name="filepath"
                          label="Files or directories"
                          className="form-control"
                          placeholder=""
                          value={data.filepath}
                          onChange={(e) => handleChange(e)}
                          type="text"

                        />
                        {data.filepath[0] != "/" && (
                          <label style={{ color: 'red' }}>You should start url with /</label>
                        )}
                      </div>
                      <Row className="mt-3" style={{ alignContent: 'center' }}>
                        <Col md={3}>
                          <span>Action</span>
                          <AvRadioGroup onChange={(e) => allowRule(e)} value={data.allowRuleVal} inline className="d-flex" name="allowRuleVal" required>
                            <AvRadio className="mt-2" customInput value="Disallow" label="Disallow" />
                            <AvRadio className="mt-2" customInput value="Allow" label="Allow" />
                          </AvRadioGroup>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField
                              name="robot"
                              label="Robot"
                              className="form-control"
                              placeholder=""
                              type="select"
                              value={data.robot ? data.robot : 'All'}
                              onChange={(e) => setData({ ...data, robot: e.target.value })}
                              required
                            >
                              <option value={'All'}>All</option>
                              <option value={'Goolgebot'}>Googlebot</option>
                              <option value={'Googlebot-Mobile'}>Googlebot-Mobile</option>
                              <option value={'Googlebot-Image'}>Googlebot-Image</option>
                              <option value={'Mediapartners-Google'}>Mediapartners-Google</option>
                              <option value={'Adsbot-Google'}>Adsbot-Google</option>
                              <option value={'Slurp'}>Slurp</option>
                              <option value={'msnbot'}>msnbot</option>
                              <option value={'msnbot-media'}>msnbot-media</option>
                              <option value={'Teoma'}>Teoma</option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className='d-flex justify-content-around'
                            style={{
                              height: ' 100%',
                              alignItems: 'center'
                            }}
                          >
                            <button
                              className="btn left_btn"
                              type="button"
                              onClick={() => AddRule()}

                            >
                              Add
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* Arrrryyy */}
                  {data.RuleArr?.map((item, index) => {
                    return (
                      <div>
                        <div className="mb-">
                          <AvField
                            name="filepath"
                            label="Files or directories"
                            className="form-control"
                            placeholder=""
                            value={item?.filepath}
                            onChange={(e) => updateArr(e, index)}
                            type="text"
                          />
                        </div>
                        <Row className="mt-3" style={{ alignContent: 'center' }}>
                          <Col md={3}>
                            <span>Action</span>
                            <AvRadioGroup onChange={(e) => updateArr(e, index)} value={item?.allowRuleVal} inline name={`itemAction${index}`}  >
                              <div className='d-flex justify-content-around'>
                                <AvRadio className="mt-2" customInput value="Disallow" >Disallow</AvRadio>
                                <AvRadio className="mt-2" customInput value="Allow" >Allow</AvRadio>
                              </div>
                            </AvRadioGroup>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="robot"
                                label="Robot"
                                className="form-control"
                                placeholder=""
                                type="select"
                                value={item?.robot ? item?.robot : 'All'}
                                onChange={(e) => updateArr(e, index)}

                              >
                                <option value={'All'}>All</option>
                                <option value={'Goolgebot'}>Googlebot</option>
                                <option value={'Googlebot-Mobile'}>Googlebot-Mobile</option>
                                <option value={'Googlebot-Image'}>Googlebot-Image</option>
                                <option value={'Mediapartners-Google'}>Mediapartners-Google</option>
                                <option value={'Adsbot-Google'}>Adsbot-Google</option>
                                <option value={'Slurp'}>Slurp</option>
                                <option value={'msnbot'}>msnbot</option>
                                <option value={'msnbot-media'}>msnbot-media</option>
                                <option value={'Teoma'}>Teoma</option>
                              </AvField>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className='d-flex justify-content-around'>
                              {/* <button
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={() => console.log('== ', data.RuleArr)}
                                >
                                  Copy
                                </button> */}
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => deleteAction(index)}
                              >
                                Delete
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )
                  })}

                  <Row>
                    <Col>
                      <div className="mt-4">
                        <button
                          className="btn left_btn w-50 waves-effect waves-light"
                          type="submit"
                        // onClick={() => downloadTxtFile()}
                        >
                          get_app
                        </button>
                      </div>
                    </Col>
                    <Col>

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <div id="MyFile" style={{ backgroundColor: '#e9ecef', padding: 20, borderRadius: "7px", }}>
                <div>
                  <p>User-Agent: * </p>
                  <p>Disallow: {data.allowallRobo == "Disallow" ? "/" : ''}</p>
                  {data.RuleArr?.map((item, index) => {
                    console.log('item robot check =>', item?.robot)
                    if (item?.robot == "All") {
                      return (
                        <div>
                          <p>
                            {item?.allowRuleVal} : {item?.filepath}
                          </p>

                        </div>
                      )
                    }
                  })}
                  {data.RuleArr?.map((item, index) => {
                    if (item?.robot !== "All") {
                      return (
                        <div>
                          <p> User-Agent: {item?.robot}</p>
                          <p> {item?.allowRuleVal}: {item?.filepath}</p>
                        </div>
                      )
                    }
                  })}
                </div>
                <p>
                  Sitemap: {data.websiteurl}
                </p>
              </div>
              {/* <p>{printData}</p> */}

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>SEOTagGenerator.com's Robots.txt File Generator is a powerful tool that allows website owners to easily create and customize their website's robots.txt file. A robots.txt file is a small but crucial file that tells search engine robots which pages or sections of a website should or should not be indexed. Robots.txt files use something called the Robots Exclusion Protocol. By using this tool, website owners can ensure that their website is crawled and indexed by search engines properly.
            </p>
            <p>SEOTagGenerator.com's Robots.txt File Generator is a simple and user-friendly tool that requires no coding experience. With just a few clicks, website owners can create a robots.txt file that meets their specific needs. The tool allows users to specify which pages or sections of their website should be crawled and indexed and which should not. Additionally, the tool can be used to block certain bots or user agents from crawling the website.
            </p>
            <p>Overall, SEOTagGenerator.com's Robots.txt File Generator is a valuable tool for website owners who want to ensure their website is properly crawled and indexed by search engines. With its ease of use and customization options, this tool is an excellent choice for businesses and website owners of all sizes.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>SEOTagGenerator.com's Robots.txt File Generator offers several benefits for website owners. Here are some of the key benefits:
            </p>
            <ul>
              <li>• Improved search engine visibility: A robots.txt file helps search engines understand which pages or sections of a website should be crawled and indexed. By using a robots.txt file, website owners can improve their website's search engine visibility and ensure that the most important pages are being indexed.
              </li>
              <li>• Easy to use: The Robots.txt File Generator on SEOTagGenerator.com is a user-friendly tool that requires no coding experience. It is designed to be easy to use for website owners of all skill levels.
              </li>
              <li>• Customizable: The tool allows website owners to customize their robots.txt file to meet their specific needs. Users can specify which pages or sections of their website should be crawled and indexed and which should be blocked.</li>
              <li>• Blocks unwanted bots: The tool can be used to block certain bots or user agents from crawling the website. This can be useful for preventing unwanted traffic or protecting the website from malicious bots.</li>
              <li>• Free: SEOTagGenerator.com's Robots.txt File Generator is a free tool, making it accessible to businesses and website owners of all sizes.
              </li>
              <p className='mt-3'>
                With its ease of use, customization options, and the ability to block unwanted bots, The Robots.txt File Generator tool is an excellent choice for businesses and website owners of all sizes.

              </p>
            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What is a robots.txt file?
            </h6>
            <p>A. A robots.txt file is a small but important file that tells search engine robots which pages or sections of a website should or should not be indexed. It is typically located in the root directory of a website. Robots.txt files use something called the Robots Exclusion Protocol.
            </p>
            <h6>Q. Do I need to have a robots.txt file?
            </h6>
            <p>A. While having a robots.txt file is not strictly necessary, it is recommended for websites that want to improve their search engine visibility and ensure that their website is properly crawled and indexed.
            </p>
            <h6>Q. Is the Robots.txt File Generator on SEOTagGenerator.com free to use?</h6>
            <p>A. Yes, the Robots.txt File Generator on SEOTagGenerator.com is a free tool that can be used by anyone.
            </p>
            <h6>Q. Do I need to know how to code to use the Robots.txt File Generator?
            </h6>
            <p>A. No, the Robots.txt File Generator on SEOTagGenerator.com is designed to be user-friendly and requires no coding experience.
            </p>
            <h6>Q. Can I customize my robots.txt file using the Robots.txt File Generator?
            </h6>
            <p>A. Yes, the tool allows users to customize their robots.txt file to meet their specific needs. Users can specify which pages or sections of their website should be crawled and indexed and which should be blocked.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>
              SEOTagGenerator.com's Robots.txt File Generator is a valuable tool for website owners who want to improve their website's search engine visibility and ensure that their website is properly crawled and indexed. With this tool, users can easily create a customized robots.txt file without any coding experience. The generator allows users to specify which pages or sections of their website should be crawled and indexed and which should be blocked, improving the website's search engine visibility. Additionally, the tool can be used to block certain bots or user agents from crawling the website, protecting the website from unwanted traffic or malicious bots. As a free and user-friendly tool, the SEOTagGenerator.com's Robots.txt File Generator is an excellent choice for businesses and website owners of all sizes who want to ensure that their website is properly indexed and easily found by search engines.

            </p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
