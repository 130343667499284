import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
import Breadcrumb from '../../components/Common/Breadcrumb'
import Utils from '../Utility'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';


const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusBtn, setStatusBtn] = useState(false);
  // const []

  const [data, setData] = useState({
    websiteurl: '',
    CampaignSource: '',
    CampaignMedium: '',
    CampaignName: '',
    CampaignTerms: '',
    CampaignContents: ''
  });

  const [predata, setPreData] = useState({})

  useEffect(() => {
    setPreData({

    })
  }, [data])

  const handleChange = (e) => {
    console.log('==>>', e.target.name, "  ", e.target.value);
    setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }

  const buildurl = () => {
    // Copy the text inside the text field
    //  var copyData = '<script type="application/ld+json">'+ JSON.stringify(predata) + "</script>"
    // var copyData = data.websiteurl+data.CampaignSource+data.CampaignMedium+data.CampaignName+data.CampaignTerms+data.CampaignContents
    // navigator.clipboard.writeText(copyData);
    var url = data.websiteurl
    if (url) {
      var url = data.websiteurl
      var Source = data.CampaignSource
      var medium = data.CampaignMedium
      var name = data.CampaignName
      var term = data.CampaignTerms
      var content = data.CampaignContents

      // var Nurl = ''
      var NSource = ''
      var Nmedium = ''
      var Nname = ''
      var Nterm = ''
      var Ncontent = ''

      url = url + "?"
      if (Source.length) {
        NSource = "utm_source=" + Source + "&"
      } else { NSource = Source }
      if (medium.length) {
        Nmedium = "utm_medium=" + medium + "&"
      } else { Nmedium = medium }
      if (name.length) {
        Nname = "utm_campaign=" + name + "&"
      } else { Nname = name }
      if (term.length) {
        Nterm = "utm_term=" + term + "&"
      } else { Nterm = term }
      if (content.length) {
        Ncontent = "utm_content=" + content + "&"
      } else { Ncontent = content }


      var URL = url + NSource + Nmedium + Nname + Nterm + Ncontent

      var length = URL.length
      if (URL[length - 1] == "&") {
        URL = URL.slice(0, -1)
      }
      URL = URL.replace(/ /g, "%20").replace(/@/g, "%40").replace(/&/g, "%26").replace(/-/g, "%2D").replace(/;/g, "%3B").replace(/_/g, "%5F").replace(/,/g, "%2C")
      console.log('==>', URL,)
      setCompleteurl(URL)

    }

  }

  const reset = () => {
    setData({
      websiteurl: '',
      CampaignSource: '',
      CampaignMedium: '',
      CampaignName: '',
      CampaignTerms: '',
      CampaignContents: ''
    })
  }

  const handleValidSubmit = () => {
    buildurl()
  }


  const copyurlFunction = () => {
    setStatusBtn(true);
    navigator.clipboard.writeText(Completeurl);
    setTimeout(() => {
      setStatusBtn(false);
    }, 400);

  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Free UTM Builder - SeoTagGenerator</title>
        <meta name="description" content="Our URL Builder tool helps you to generate ready-to-use URLs to track your marketing campaigns with ease. You don't need to waste your time checking if your links are tagged correctly." />
        <meta name="keywords" content="utm url generator, utm tracking code example, Free UTM Builder" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/utmurl" />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT="utm url generator, utm tracking code example, Free UTM Builder " />
        <meta name="dc.description" CONTENT="Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information." />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content=" Free UTM Builder - SeoTagGenerator " />
        <meta property="og:description" content=" Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information." />
        <meta property="og:url" content="https://www.seotaggenerator.com/utmurl" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="Free UTM Builder - SeoTagGenerator" />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Generate strong and secure passwords for free with our Strong Password Generator. Our tool creates unique and random passwords that are difficult for hackers to crack, ensuring maximum protection for your sensitive information." />
        <link rel="canonical" href="https://www.seotaggenerator.com/utmurl" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >

          {/* <h1>UTM URL Generator</h1> */}
          <Breadcrumb title={Utils.projectName} breadcrumbItem="UTM URL Generator" />
          <p className='mb-1'>Our URL Builder tool helps you to generate ready-to-use URLs to track your marketing campaigns with ease. You don't need to waste your time checking if your links are tagged correctly.</p>
          <p className='pb-2'>Fill in the form below to build your URL without putting in any extra effort:</p>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}


                    <div className="mb-3">
                      <AvField
                        name="websiteurl"
                        label="Website URL"
                        className="form-control"
                        placeholder="https://yourdomain.com/"
                        value={data.websiteurl}
                        onChange={(e) => handleChange(e)}
                        type="url"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="CampaignSource"
                        label="Campaign Source"
                        type="text"
                        value={data.CampaignSource}
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder="referrer: google, emailnewsletter2, facebook"
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="CampaignMedium"
                        label="Campaign Medium"
                        value={data.CampaignMedium}
                        onChange={(e) => handleChange(e)}
                        placeholder="marking medium: cpc, banner, email, social"
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="CampaignName"
                        label="Campaign Name"
                        value={data.CampaignName}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                        placeholder="e.g. product, promocode, slogan"
                      />
                    </div>

                    {/* "imagetype": "", */}
                    <div className="mb-3">
                      <AvField
                        name="CampaignTerm"
                        label="Campaign Term"
                        className="form-control"
                        placeholder="(optional) identify the paid keywords"
                        value={data.CampaignTerms}
                        onChange={(e) => handleChange(e)}
                        type="text"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="CampaignContent"
                        label="Campaign Content"
                        className="form-control"
                        placeholder="(optional) use to differentiate ads"
                        value={data.CampaignContents}
                        onChange={(e) => handleChange(e)}
                        type="text"
                      />
                    </div>

                  </div>
                  <Row>
                    <Col>
                      <div className="mt-4 btn_section">
                        <button
                          className="btn left_btn w-50 waves-effect waves-light"
                          type="submit"
                          onClick={() => reset()}
                        >
                          Reset
                        </button>
                        <button
                          className="btn right_btn w-50 waves-effect waves-light"
                          type="submit"
                        // onClick={()=> buildurl()}
                        >
                          Build URL
                        </button>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <div className='urs_box'>
                {Completeurl ?
                  <p>{Completeurl}</p> : <p>UTM URL Generator Here</p>
                }

                <button className='copy_icon_btn' onClick={copyurlFunction}>
                  {statusBtn == false ? <i className="dripicons-copy" /> : <i className="mdi mdi-check" />}
                </button>
              </div>

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>The UTM URL Generator on seotaggenerator.com is a powerful tool that allows you to easily create custom UTM parameters for your URLs. UTM parameters are tags that you can add to your website's URLs to track where your traffic is coming from and how visitors are interacting with your content. By using UTM parameters, you can gain valuable insights into your website's performance and make data-driven decisions to improve your online presence. With the UTM URL Generator, you can create custom UTM parameters in just a few clicks, without any coding knowledge required.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>The UTM URL Generator on seotaggenerator.com offers numerous benefits that can help improve your website's marketing and analytics efforts. Some of the benefits include:
            </p>
            <ul>
              <li>• Improves search engine visibility: By adding schema markup code to your website, you can provide search engines with more information about your content, products, or services. This, in turn, can lead to better search engine rankings and increased visibility.
              </li>
              <li>• Enhanced tracking: With UTM parameters added to your URLs, you can easily track and analyze the performance of your marketing campaigns, social media posts, and other promotional activities.</li>
              <li>• Improved campaign performance: By tracking the performance of your campaigns using UTM parameters, you can identify which channels and campaigns are driving the most traffic and conversions, and optimize your marketing efforts accordingly.</li>
              <li>• Easy to use: The UTM URL Generator on seotaggenerator.com is simple and easy to use, with an intuitive interface that allows you to create UTM-tagged URLs in seconds.
              </li>
              <li>• Using the UTM URL Generator can help you gain valuable insights into your website's traffic and optimize your marketing efforts for better performance.
              </li>

            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q.What is a UTM URL, and why is it important for tracking website traffic?
            </h6>
            <p>A UTM URL is a unique link that includes tags that help track the source, medium, and campaign name of the traffic to a website. By using UTM tags, marketers can better understand which channels and campaigns are driving the most traffic and conversions, allowing them to optimize their marketing strategies accordingly.
            </p>
            <h6>Q.Can I use the UTM URL generator to track social media campaigns?
            </h6>
            <p>Yes, the UTM URL generator can be used to track social media campaigns. Simply enter the appropriate information in the tool's fields, including the social media platform as the source and the campaign name.
            </p>
            <h6>Q.Do I need technical knowledge to use the UTM URL generator on seotaggenerator.com?
            </h6>
            <p>No, you don't need any technical knowledge to use the UTM URL generator on seotaggenerator.com. It's designed to be user-friendly and intuitive, so anyone can use it to create UTM parameters for their website's URLs.
            </p>
            <h6>Q.How many UTM parameters can I add to a single URL?
            </h6>
            <p>Google recommends using no more than five UTM parameters per URL to avoid cluttering the URL and making it difficult to read. However, you can add as many UTM parameters as you need to track the specific data points that are important to your business.
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>Using UTM URL Generator on seotaggenerator.com can provide several benefits to website owners and marketers.Here is why you should use it:
            </p>
            <p>One of the main benefits is that it allows you to track the performance of your campaigns more accurately. By adding UTM parameters to your URLs, you can track clicks and conversions from specific campaigns, channels, and sources in your analytics platform. This information can help you make data-driven decisions and optimize your marketing efforts for better results.</p>
            <p>Another benefit of using the UTM URL Generator on seotaggenerator.com is that it saves you time and effort. Rather than manually adding UTM parameters to each URL, you can generate them quickly and easily using our tool. This makes it much easier to create consistent and organized tracking for your campaigns.
            </p>
            <p>Finally, the UTM URL Generator on seotaggenerator.com helps you ensure that your UTM parameters are correct and follow best practices. This can be especially helpful for those who are new to UTM tracking and want to make sure they are setting up their parameters correctly.</p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
