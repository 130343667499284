import React from 'react';
import { BallTriangle  } from 'react-loader-spinner';
const loading = require('../../assets/images/loading.gif');

const Loader = (props) => {
    if (props.visible)
        // if(true)
        return (
            <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#00000088', zIndex: 1051, top: 0, display: 'flex', position: 'absolute' }}>
                {/* <BallTriangle
                    height={100}
                    width={150}
                    radius={5}
                    color="#324fc4"
                    secondaryColor= '#4fa94d'
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle={{}}
                    visible={true}
                /> */}
                <img src={loading} style={{height:100, width:100, backgroundColor:'transparent'}} />
            </div>
        )
    else
        return null;
}

export default Loader;