import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Container
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { getProfile } from '../../store/auth/profile/actions';
import Loader from "../../components/Loader";
// import LeadSingle from './LeadSingle';
// import CodeView from 'react-code-view' ;
// import 'react-code-view/styles/react-code-view.css';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { toast } from 'react-toastify'
import Disclaimer from '../../components/HorizontalLayout/Disclaimer'
import Footer from '../../components/HorizontalLayout/Footer'
import { Helmet } from 'react-helmet'

const Leads = props => {
  const { user } = props;
  const [Completeurl, setCompleteurl] = useState('');
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    URL: '',
    Title: '',
  });

  const [predata, setPreData] = useState({})

  useEffect(() => {
    setPreData({
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": "1",
          "name": data.Title ? data.Title : "",
          "item": data.URL ? data.URL : ""
        }

      ]
    }
    )
  }, [data])

  const handleChange = (e) => {
    console.log('==>>', e.target.name, "  ", e.target.value);
    setData({ ...data, [e.target.name]: e.target.value ? e.target.value : "" })
  }

  const CopyURL = () => {
    // Copy the text inside the text field

    // var url = data.websiteurl
    if (data.URL && data.Title) {
      var copyData = '<script type="application/ld+json">\n{\n' + JSON.stringify(predata) + "\n}\n</script>"
      // var copyData = data.websiteurl+data.CampaignSource+data.CampaignMedium+data.CampaignName+data.CampaignTerms+data.CampaignContents
      navigator.clipboard.writeText(copyData);
      toast.success("Copied")
    }
    else {
      toast.error('Please enter a Valid home page title and URL.')
    }

  }

  const reset = () => {
    setData({
      URL: '',
      Title: '',
    })
  }

  const handleValidSubmit = () => {
    CopyURL()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Free Breadcrumbs Generator Tool - SeoTagGenerator </title>
        <meta name="description" content="Generate breadcrumbs with ease using our Breadcrumb Generator tool! Improve your website's navigation structure, user experience, and search engine optimization. Perfect for e-commerce websites, blogs, and other content-heavy websites. Get started now and boost your website's technical SEO." />
        <meta name="keywords" content="Free Breadcrumbs Schema Markup Generator, Breadcrumbs Generator Tools, Breadcrumbs Schema Generator" />
        <meta name="dc.language" CONTENT="English" />
        <meta name="dc.source" CONTENT="https://www.seotaggenerator.com/breadcrumbs" />
        <meta name="dc.title" CONTENT="SeoTagGenerator " />
        <meta name="dc.keywords" CONTENT="Free Breadcrumbs Schema Markup Generator, Breadcrumbs Generator Tools, Breadcrumbs Schema Generator" />
        <meta name="dc.description" CONTENT="Generate breadcrumbs with ease using our Breadcrumb Generator tool! Improve your website's navigation structure, user experience, and search engine optimization. Perfect for e-commerce websites, blogs, and other content-heavy websites. Get started now and boost your website's technical SEO." />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Gurgaon" />
        <meta name="Author" content=" SeoTagGenerator " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Free Breadcrumbs Generator Tool - SeoTagGenerator" />
        <meta property="og:description" content="Generate breadcrumbs with ease using our Breadcrumb Generator tool! Improve your website's navigation structure, user experience, and search engine optimization. Perfect for e-commerce websites, blogs, and other content-heavy websites. Get started now and boost your website's technical SEO." />
        <meta property="og:url" content="https://www.seotaggenerator.com/breadcrumbs" />
        <meta property="og:site_name" content="seotaggenerator.com" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="Free Breadcrumbs Generator Tool - SeoTagGenerator " />
        <meta name="twitter:image" content="" />
        <meta property="twitter:description" content="Generate breadcrumbs with ease using our Breadcrumb Generator tool! Improve your website's navigation structure, user experience, and search engine optimization. Perfect for e-commerce websites, blogs, and other content-heavy websites. Get started now and boost your website's technical SEO." />
        <link rel="canonical" href="https://www.seotaggenerator.com/breadcrumbs" />

      </Helmet>
      <div className="page-content pt-5">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Bread crumbs Generator" />
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="p-2">

                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}


                    <div className="mb-3">
                      <AvField
                        name="Title"
                        label="Your homepage title: *"
                        className="form-control"
                        placeholder="Page Title"
                        value={data.Title}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="URL"
                        label="URL for home page: *"
                        type="url"
                        value={data.URL}
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder="Page URL"
                      />
                    </div>


                  </div>
                  <Row>
                    <Col>
                      <div className="mt-4 btn_section">
                        <button
                          className="btn left_btn w-50 waves-effect waves-light"
                          type="submit"
                          onClick={() => reset()}
                        >
                          Reset
                        </button>
                        <button
                          className="btn right_btn w-50 waves-effect waves-light"
                          type="submit"
                        // onClick={() => copyurl()}
                        >
                          Copy URL
                        </button>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <JSONInput
                id='a_unique_id'
                placeholder={predata}
                // colors      = { darktheme }
                locale={locale}
                height='550px'
                width='100%'
                viewOnly={true}
              />

            </Col>

          </Row>
        </AvForm>
      </div>
      <footer className="footer">
        <Footer />
        <hr className="footer_hr" />
        <div className="container-fluid">
          <div className="footer-content padding_60px" id='about'>
            <h5>Introduction</h5>
            <p>Breadcrumbs are a type of navigational aid that helps website visitors understand their current location within a website's hierarchy. Breadcrumbs are displayed as a trail of links that show the path a user took to arrive at their current location.
            </p>
            <p>Breadcrumbs have become an essential part of website design and development as they provide a clear and concise way for users to navigate through a website's structure. They also help search engines understand the relationship between pages on a website, making it easier for them to index and rank content.
            </p>
            <p>At seotaggenerator.com, we offer a Breadcrumbs Generator that can help you quickly and easily generate breadcrumb navigation for your website. Our tool allows you to customize the look and feel of your breadcrumbs to match your website's design.</p>
            <p>Using our Breadcrumbs Generator can improve the overall user experience of your website, making it easier for users to find what they are looking for and navigate through your site. Additionally, it can help improve your website's SEO by providing search engines with a clear understanding of your site's structure.</p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Benefits</h5>
            <p>The Breadcrumbs Generator on seotaggenerator.com is a tool that allows website owners to easily create breadcrumb navigation for their website. Breadcrumb navigation is a type of secondary navigation that shows the user's location within the website hierarchy and allows them to easily navigate to previous pages. The benefits of using the Breadcrumbs Generator include:</p>
            <ul>
              <li>• Improved user experience: Breadcrumbs provide users with an easy-to-understand visual representation of where they are in the website hierarchy, making it easier for them to navigate and find the information they are looking for.
              </li>
              <li>• Increased search engine visibility: Breadcrumbs provide search engines with additional context about the structure of a website, making it easier for them to crawl and index the site's pages.</li>
              <li>• Better site structure: Breadcrumbs help to organize a website's content into a clear and hierarchical structure, making it easier for both users and search engines to understand.
              </li>
              <li>• Customizable options: The Breadcrumbs Generator on seotaggenerator.com allows users to customize the look and feel of their breadcrumb navigation, ensuring that it fits seamlessly with the rest of their website's design.
              </li>

            </ul>
          </div>
          <div className="footer-content padding_60px" id='faq'>
            <h5>FAQs</h5>
            <h6>Q. What is a breadcrumbs generator?
            </h6>
            <p>A breadcrumbs generator is a tool that helps website owners create breadcrumbs, a navigational aid that displays the user's location on a website.</p>
            <h6>Q. Why do I need breadcrumbs on my website?</h6>
            <p>Breadcrumbs provide an intuitive way for users to navigate your website, improving their user experience and reducing bounce rates. Additionally, search engines can use breadcrumbs to understand the structure of your website, potentially improving your website's search engine rankings.</p>
            <h6>Q. Is the breadcrumbs generator free to use?
            </h6>
            <p>Yes, the breadcrumbs generator on seotaggenerator.com is completely free to use
            </p>
          </div>
          <div className="footer-content padding_60px">
            <h5>Why should you use it?</h5>
            <p>Using breadcrumbs on your website can greatly enhance user experience and make it easier for visitors to navigate through your content. The Breadcrumbs Generator on seotaggenerator.com is a powerful tool that allows you to create breadcrumbs that are both functional and visually appealing. Here are some reasons why you should use the Breadcrumbs Generator:</p>
            <p>Firstly, breadcrumbs help improve the user experience by providing a clear and easy-to-follow path for visitors. This is particularly useful for websites with a lot of content, such as e-commerce sites, where visitors may need to navigate through multiple categories and subcategories.</p>
            <p>Secondly, breadcrumbs can help improve the structure of your website, making it easier for search engines to crawl and index your pages. By including breadcrumbs, you provide search engines with additional information about the organization of your content, which can lead to better rankings in search results.
            </p>
            <p>Thirdly, using the Breadcrumbs Generator on seotaggenerator.com can save you time and effort by automating the process of creating breadcrumbs. Rather than having to manually create breadcrumbs for each page of your website, you can simply generate it by very short information..
            </p>
            <p>Finally, the Breadcrumbs Generator on seotaggenerator.com is easy to use and accessible to everyone, regardless of their level of technical expertise. Whether you're a seasoned web developer or a beginner just starting out, the generator can help you create effective and user-friendly breadcrumbs for your website.</p>
          </div>
        </div>


        <hr className="footer_hr" />
        <Disclaimer />

      </footer>
    </React.Fragment>
  )
}

Leads.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Leads)
)
